import {Button, AppShell, Indicator, Text} from "@mantine/core";
import React, {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {
  GetUserNotificationsCountDocument,
  UserType,
} from "/@/generated/graphql";
import {api} from "/@/services/api/api";
import {useAuthContext} from "/@/shared/contexts/AuthContext";
import {useQuery} from "@apollo/client";
import {IconBell} from "@tabler/icons-react";

import {
  isUserStaff,
} from "@jasper/shared";

export const LayoutAppBar: FC = () => {
  const {logIn, logOut, user} = useAuthContext();
  const navigate = useNavigate();

  const {data: getUserNotificationsCount} = useQuery(
    GetUserNotificationsCountDocument,
    {fetchPolicy: "network-only"},
  );

  useEffect(() => {
    const checkIfLoggedIn = async () => {
      const user = await api.get("/users/me");
      return user;
    };
    checkIfLoggedIn()
      .then(res => {
        logIn(res.data);
      })
      .catch(() => {
        logOut();
      });
  }, []);

  return (
    <AppShell.Header
      p="sm"
      style={() => ({
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        backgroundColor: "white",
        height: "60px",
      })}
    >
      <Indicator
        color="red"
        disabled={
          !getUserNotificationsCount?.getUserNotificationsCount ||
          getUserNotificationsCount?.getUserNotificationsCount < 1
        }
        style={{display: "flex", alignItems: "center", cursor: "pointer"}}
        mr="md"
        onClick={() => navigate("/notifications")}
      >
        <IconBell strokeWidth="1.25" />
      </Indicator>
      <Text
        size="md"
        style={{fontWeight: "600"}}
      >
        {user?.type === UserType.Dentist && !user?.managedUserGroup
          ? "Dr."
          : ""}{" "}
        {user?.firstName} {user?.lastName}
      </Text>
      {(
        isUserStaff(user)
        || import.meta.env.MODE !== "production"
        || user?.allowPhysicalPrint
      ) && (
        <Button
          color="green"
          onClick={() => navigate("/create-order-form")}
          ml={20}
          data-testid="new-order-button"
        >
          Nouvelle commande
        </Button>
      )}
    </AppShell.Header>
  );
};
