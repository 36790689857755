import {ApolloProvider} from "@apollo/client";
import React from "react";
import {createRoot} from "react-dom/client";
import {ApiInterceptors} from "./services/api/api";
import App from "/@/App";
import {AuthProvider} from "./shared/contexts/AuthContext";
import {MantineProvider} from "@mantine/core";
import {MantineEmotionProvider} from "@mantine/emotion";
import {theme} from "/@/shared/theme/theme";
import {IpcContextProvider} from "/@/shared/contexts/IpcContext";
import {CustomFonts} from "/@/components/fonts";
import {ModalsProvider} from "@mantine/modals";
import {Notifications} from "@mantine/notifications";
import {PostHogProvider} from "posthog-js/react";
import posthog from "posthog-js";
import * as Sentry from "@sentry/react";
import client from "/@/apolloClient";

posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only",
});

Sentry.init({
  dsn: import.meta.env.VITE_PUBLIC_SENTRY_KEY,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/jasperdental\.fr\/api/],
  replaysSessionSampleRate: import.meta.env.MODE === "production" ? 0.1 : 0,
  replaysOnErrorSampleRate: import.meta.env.MODE === "production" ? 1.0 : 0,
});

const container = document.getElementById("root");
//eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <IpcContextProvider>
        <MantineProvider theme={theme}>
          <MantineEmotionProvider>
            <Notifications position="bottom-center" />
            <CustomFonts />
            <ApiInterceptors>
              <ModalsProvider>
                <PostHogProvider options={posthog}>
                  <ApolloProvider client={client}>
                    <App />
                  </ApolloProvider>
                </PostHogProvider>
              </ModalsProvider>
            </ApiInterceptors>
          </MantineEmotionProvider>
        </MantineProvider>
      </IpcContextProvider>
    </AuthProvider>
  </React.StrictMode>,
);
