import React from "react";
import {Select, Space, Text, Image} from "@mantine/core";
import {Controller} from "react-hook-form";
import MultiShadeSVG from "/@/assets/images/multishade.svg";

export const MultiShadeForm = (props: {
    control : any;
    shadeFormChoices: {value:string,label:string}[];
    errors: any;
    watchIsMultiShade: "YES"|"NO";
    watchGingivalShade: readonly (string | undefined)[];
    watchBaseShade:readonly (string | undefined)[]
    watchIncisalShade:readonly (string | undefined)[]
}) =>{
    return (
        <>
        <div style={{display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "5px"}}>
          <div style={{gridColumn: "1 / 3"}}>
            <Text
              size="sm"
              style={{fontWeight:"bold"}}
            >
              {"Teinte tiers incisal"}
            </Text>
            <Controller
              name="incisalShade"
              rules={{ required: false }}
              control={props.control}
              render={({field}) => (
                <Select
                  {...field}
                  data={props.shadeFormChoices}
                  placeholder="Choisissez une teinte de tiers incisal"
                />
              )}
            />
            {props.errors?.incisalShade?.type === "required" && (
              <Text
                size="sm"
                style={{color: "red"}}
              >
                Veuillez choisir une teinte de tiers incisal
              </Text>
            )}
            <Space h="md"/>
            <Text
              size="sm"
              style={{fontWeight:"bold"}}
            >
              {"Teinte tiers médian"}
            </Text>
            <Controller
              name="baseShade"
              rules={{
                required:
                  String(props.watchIsMultiShade) === "YES" ? true : false,
              }}
              control={props.control}
              render={({field}) => (
                <Select
                  {...field}
                  data={props.shadeFormChoices}
                  placeholder="Choisissez une teinte de tiers médian"
                />
              )}
            />
            {props.errors?.baseShade?.type === "required" && (
              <Text
                size="sm"
                style={{color: "red"}}
              >
                Veuillez choisir une teinte de base
              </Text>
            )}
            <Space h="md"/>
            <Text
              size="sm"
              style={{fontWeight:"bold"}}
            >
              {"Teinte tiers cervical"}
            </Text>
            <Controller
              name="gingivalShade"
              rules={{ required: false }}
              control={props.control}
              render={({field}) => (
                <Select
                  {...field}
                  data={props.shadeFormChoices}
                  placeholder="Choisissez une teinte de gencive"
                />
              )}
            />
            {props.errors?.gingivalShade?.type === "required" && (
              <Text
                size="sm"
                style={{color: "red"}}
              >
                Veuillez choisir une teinte de gencive
              </Text>
            )}
          </div>
          <div style={{display:"flex",position:"relative", fontSize:"14px"}}>
            <div style={{margin: "auto",position:"relative", width: "150px"}}>
              <Image src={MultiShadeSVG}/>
              <span style={{position:"absolute", width: "100%", left: 0, textAlign:"center", top:"8%"}}>
                {props.watchIncisalShade ? props.shadeFormChoices.find(obj => obj.value === props.watchIncisalShade)?.label : "" }
              </span>
              <span style={{position:"absolute", width: "100%", left: 0, textAlign:"center", top:"43%"}}>
                {props.watchBaseShade ? props.shadeFormChoices.find(obj => obj.value === props.watchBaseShade)?.label : "" }
              </span>
              <span style={{position:"absolute", width: "100%", left: 0, textAlign:"center", top:"77%"}}>
              {props.watchGingivalShade ? props.shadeFormChoices.find(obj => obj.value === props.watchGingivalShade)?.label : "" }
              </span>
            </div>
          </div>
        </div>
        <Space h="md"/>
      </>
    );
};