import {
  FixedProsthesisPreferences,
  GetUserProductPreferencesQuery,
  ItemType,
} from "/@/generated/graphql";

export const getFixedProsthesisPreferences = (
  productType: ItemType,
  productPreferences: GetUserProductPreferencesQuery,
): Partial<FixedProsthesisPreferences> | null => {
  const fixedProsthesisPreferences =
    productPreferences.getUserProductPreferences.fixedProsthesisPreferences;
  let preferenceItemType = productType;
  if (
    productType === ItemType.Lay ||
    productType === ItemType.Inlay ||
    productType === ItemType.Overlay ||
    productType === ItemType.Onlay
  ) {
    preferenceItemType = ItemType.Lay;
  }
  if (productType === ItemType.PostAndCoreRetainedCrown) {
    preferenceItemType = ItemType.InlayCore;
  }
  if (fixedProsthesisPreferences) {
    return (
      fixedProsthesisPreferences.find(
        itemPreference => itemPreference.itemType === preferenceItemType,
      ) ?? null
    );
  } else {
    return null;
  }
};
