import React, { useRef } from 'react';
import './button-file-input.css';
import { Image } from '../utils/carousel.utils'
import {
  Button,
  MantineProvider,
} from "@mantine/core";

import {
  STYLE,
} from "@jasper/shared";

export interface ButtonFileInputProps {
  onFilesAdd: (files: FileList) => void,
  buttonText: ReactNode,
  accept: string
}

export const ButtonFileInput = ({
  ...props
}: ButtonFileInputProps) => {
  const fileInput = useRef<any>(null);
  
  return (
    <MantineProvider>
      <Button
        style={{ textAlign: "center", backgroundColor: "#3C61E3" }}
        onClick={() => { fileInput.current.click(); }}
      >
        {props.buttonText}
      </Button>
      <input
        ref={fileInput}
        onChange={files => props.onFilesAdd([...files.target.files??[]])}
        style={{ display: "none" }}
        multiple
        type="file"
        accept={props.accept}
      />
    </MantineProvider>
  )
}
