import React, {FC} from "react";
import {
  GetManyOrdersOfUserQuery,
  Order,
  OrderStatus,
  UpdateOrderDocument,
} from "/@/generated/graphql";
import {Box, Button, Text, Title, rem} from "@mantine/core";
import {createStyles} from "@mantine/emotion";
import {useNavigate} from "react-router-dom";
import {OrderStatusStepper} from "/@/pages/orders/OrderStatusStepper";
import {getProductsOrderedCountLabel} from "/@/shared/utils/products/products.utils";
import {openConfirmModal} from "@mantine/modals";
import {notifications} from "@mantine/notifications";
import {useMutation} from "@apollo/client";
import {useAuthContext} from "/@/shared/contexts/AuthContext";

import {
  getOrderStatusStep,
  getPatientLabelFirstLine,
} from "@jasper/shared";

type OrderSummaryRowProps = {
  order: GetManyOrdersOfUserQuery["getManyOrdersOfUser"][0];
};

export const OrderSummaryRow: FC<OrderSummaryRowProps> = ({order}) => {
  const navigate = useNavigate();
  const orderStatus = order.status;
  const {classes} = useStyles({orderStatus});
  const {user} = useAuthContext();

  const [updateOrder] = useMutation(UpdateOrderDocument, {
    refetchQueries: ["getManyOrdersOfUser"],
  });

  const openDeleteModal = () => {
    openConfirmModal({
      title: "Supprimer la commande",
      centered: true,
      children: (
        <Text size="sm">Voulez-vous vraiment supprimer cette commande ? </Text>
      ),
      labels: {confirm: "Supprimer", cancel: "Annuler"},
      confirmProps: {color: "red"},
      onCancel: () => null,
      onConfirm: () => {
        updateOrder({
          variables: {
            where: {
              id: order.id,
            },
            data: {
              isArchived: {
                set: true,
              },
            },
          },
        }).then(() => {
          notifications.show({
            color: "green",
            title: "Commande supprimée",
            message: "",
          });
        });
      },
    });
  };

  return (
    <Box
      key={order.id}
      className={classes.row}
      onClick={
        orderStatus === OrderStatus.Draft
          ? undefined
          : () => navigate(`/order/${order.id}`)
      }
    >
      <Box>
        <Title order={5}>
          {orderStatus === OrderStatus.Draft
            ? `${
                user?.managedUserGroup
                  ? order.user.id === user?.id
                    ? `${order.user.firstName} ${order.user.lastName} : `
                    : `Dr. ${order.user.lastName} : `
                  : ""
              }Commande pour ${getPatientLabelFirstLine(order)} non terminée`
            : `${getPatientLabelFirstLine(order)}${
                user?.managedUserGroup ? ` (Dr. ${order.user.lastName})` : ""
              }${(order?.Redo ?? []).length > 0 ? " - refabrication" : ""}`}
        </Title>

        <Text
          size="sm"
          truncate
          w={250}
          style={{textOverflow: "ellipsis", fontStyle: "italic"}}
        >
          {`${getProductsOrderedCountLabel(order)}`}
        </Text>
      </Box>
      {orderStatus === OrderStatus.Draft ? (
        <Box
          style={theme => ({
            display: "flex",
            alignItems: "center",
            gap: theme.spacing.md,
          })}
        >
          <Text
            size="sm"
            style={{cursor: "pointer", fontWeight: "bold"}}
            onClick={openDeleteModal}
          >
            Supprimer
          </Text>
          <Button
            style={{cursor: "pointer"}}
            onClick={() => navigate("/create-order", {state: {id: order.id}})}
          >
            Continuer
          </Button>
        </Box>
      ) : (
        <OrderStatusStepper
          order={order as Order}
          orderStatus={getOrderStatusStep(order.status)}
        />
      )}
    </Box>
  );
};

const useStyles = createStyles(
  (theme, {orderStatus}: {orderStatus: OrderStatus}) => ({
    container: {
      padding: theme.spacing.lg,
      width: "100%",
    },
    row: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "5rem",
      margin: `${theme.spacing.sm} 0.5rem`,
      // marginBottom: theme.spacing.md,
      borderRadius: theme.radius.md,
      border: `${rem(2)} solid ${
        orderStatus === OrderStatus.Draft
          ? theme.colors.pink[3]
          : theme.colors.greyScale[8]
      }`,
      padding: `0 ${theme.spacing.lg}`,
      cursor: orderStatus === OrderStatus.Draft ? "auto" : "pointer",
      backgroundColor:
        orderStatus === OrderStatus.Draft
          ? theme.colors.pink[0]
          : theme.colors.white,
    },
  }),
);
