import {
  UserType,
  UserRole,
} from "../types/enums";

export const isUserProvider = (user: { type: UserType }): boolean => {
  return (user.type === UserType.SUPPLIER)
};

export const isUserStaff = (user: { type: UserType }): boolean => {
  return (user.type === UserType.JASPER);
};

export const isUserAdmin = (user: { type: UserType }): booleam => {
  return (user.type === UserType.JASPER && user.role === UserRole.ADMIN);
};

export const isUserStaffNotAdmin = (user: { type: UserType }): booleam => {
  return (user.type === UserType.JASPER && user.role === UserRole.STAFF);
};

export const getDomain = (env) => {
  switch (env) {
    case "staging":
      return ".jasperdental.fr";
    case "production":
      return ".jasperdental.fr";
    default:
      return "localhost";
  }
}
