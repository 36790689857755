import {Loader} from "@mantine/core";
import React, {FC, useEffect} from "react";
import {useCreateOrder} from "./useCreateOrder";
import {useIpcContext} from "/@/shared/contexts/IpcContext";
import {useLocation} from "react-router-dom";
import {CreateProductPage} from "/@/pages/products/create/CreateProductPage";
import {CreateAnatomyItemStep, CreateOrderStep} from "/@/shared/types/orders/createOrder.types";
import {CreateOrderPatientInfo} from "/@/pages/orders/create/CreateOrderPatientInfo";
import {CreateOrderReview} from "../CreateOrderReview";
import {useCreateOrderStore} from "/@/shared/stores/orders/create-store.store";
import { ItemType } from "/@/generated/graphql";
import { isBridgeAndInlayCoreOverlapping } from "@jasper/shared";

export const CreateOrderPage: FC = () => {
  const {state} = useLocation();
  const {existingDraftOrderLoading, order, refetchOrder} = useCreateOrder(
    state ? {newOrderId: state.id} : undefined,
  );
  const {creatingOrderId, setCreatingOrderId} = useIpcContext();
  const [
    newOrderId,
    createOrderStep,
    createProductStep,
    createProductIndex,
    updateNewOrderId,
    updateCreateOrderStep,
    updateCreateProductIndex,
    updateCreateProductStep,
  ] = useCreateOrderStore(state => [
    state.newOrderId,
    state.createOrderStep,
    state.createProductStep,
    state.createProductIndex,
    state.updateNewOrderId,
    state.updateCreateOrderStep,
    state.updateCreateProductIndex,
    state.updateCreateProductStep,
  ]);

  useEffect(() => {
    if (creatingOrderId) {
      setCreatingOrderId(null);
    }
  }, []);

  // Monitoring order if needed
  // useEffect(() => {
  //   if (order) {
  //     console.log("order from createorderpage", order);
  //   }
  // });

  useEffect(()=>{
    if (
      order?.products &&
      order?.products.filter(
      value =>
      value.productType === ItemType.Bridge ||
      value.productType === ItemType.InlayCore,
    ).length === 2
      && isBridgeAndInlayCoreOverlapping(order?.products)
      && createProductIndex === 1
      && createProductStep === CreateAnatomyItemStep.Preferences
    ) {
      if (order?.products?.length === 2) {
        updateCreateOrderStep(CreateOrderStep.Review);
        updateCreateProductStep(0);
      }
      else {
        updateCreateProductIndex(createProductIndex + 1);
        updateCreateProductStep(0);
      }
    }
  },[createProductStep]);

  useEffect(() => {
    if (
      order &&
      order.products &&
      createProductIndex > order?.products.length - 1
    ) {
      updateCreateOrderStep(CreateOrderStep.Review);
    } else {
      updateCreateProductStep(0);
    }
  }, [createProductIndex]);

  useEffect(() => {
    if (createOrderStep === CreateOrderStep.Review && order?.products) {
      updateCreateProductIndex(order?.products?.length - 1);
    }
  }, [createOrderStep]);

  useEffect(() => {
    if (newOrderId === null && order) {
      updateNewOrderId(order.id);
    } else if (order && order.id && newOrderId !== order.id) {
      updateCreateOrderStep(CreateOrderStep.Patient);
      updateCreateProductIndex(0);
      updateCreateProductStep(0);
      updateNewOrderId(order.id);
    }
  }, [order]);

  if (existingDraftOrderLoading) {
    return <Loader />;
  }

  if (!order || !order.products) {
    return <div>ERROR gql</div>;
  }
  const numberOfProducts = order.products.length;
  if (numberOfProducts < 1) {
    return <div>{"Aucun produit à créer pour cette commande"}</div>;
  }

  if (createOrderStep === CreateOrderStep.Patient) {
    return <CreateOrderPatientInfo order={order} />;
  } else if (
    createOrderStep === CreateOrderStep.Product &&
    createProductIndex >= 0 &&
    createProductIndex < order.products.length
  ) {
    return (
      <CreateProductPage
        product={order.products[createProductIndex]}
        allProducts={order.products}
        orderCreationProductOrder={0}
        patient={order.patient}
        dentist={order.user}
        order={order}
      />
    );
  } else if (createOrderStep === CreateOrderStep.Review) {
    return (
      <CreateOrderReview
        order={order}
        refetchOrder={refetchOrder}
        // setCreateOrderStep={setCreateOrderStep}
      />
    );
  }
  return (
    <div>
      {
        "Une erreur s'est produite lors de la création de votre commande. Veuillez contacter l'équipe Jasper."
      }
    </div>
  );
};
