import {Box, Select, Space, Text, Title} from "@mantine/core";
import React, {FC, useState, useEffect} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {ButtonsSelect} from "/@/components/ButtonsSelect";
import {StepsButtons} from "/@/components/StepsButtons";
import {
  BridgeType,
  GetImplantItemChoicesQuery,
  ItemType,
  CreateImplantItemDocument,
  DeleteManyImplantItemDocument,
  RetentionType,
  GetAccountProductPreferencesDocument,
  TeethShadeSide,
  TeethShadeType,
  MultiShadeInfo,
} from "/@/generated/graphql";
import {CreateImplantItemStep} from "../../../../shared/types/orders/createOrder.types";
import {useMutation, useQuery} from "@apollo/client";
import {ProductFromGetOrderByUniqueAttributeQuery} from "/@/shared/types/products/products.types";
import {useCreateOrderStore} from "/@/shared/stores/orders/create-store.store";
import {
  yesNoChoices,
  RetentionTypeChoices,
} from "../../../../shared/types/products/products.types";
import {BridgeComponent} from "../../../../components/items/index";
import {useAuthContext} from "../../../../shared/contexts/AuthContext";

import {
  FixedProsthesisShadeType,
  findUserPreference,
  filterShadeByShadeType,
  formatChoicesData,
  getAllPossibleSplintedCrown,
  getAllNonSplintedCrown,
} from "@jasper/shared";
import { MultiShadeForm } from "/@/components/items/Multishade";

type ImplantItemFormData = {
  material: string;
  shade: string;
  layType?: ItemType;
  bridgeType?: BridgeType;
  isSplintedCrown: string;
  retentionType?: RetentionType;
};

type CreateOrderImplantItemItemDetailsProps = {
  product: NonNullable<ProductFromGetOrderByUniqueAttributeQuery>;
  implantItemChoicesData: GetImplantItemChoicesQuery;
};
export const CreateOrderImplantItemItemDetails: FC<
  CreateOrderImplantItemItemDetailsProps
> = ({product, implantItemChoicesData, order}) => {
  const [updateCreateProductStep] = useCreateOrderStore(state => [
    state.updateCreateProductStep,
  ]);

  const {user} = useAuthContext();

  const newImplantItem = product.implantItem.find(
    item => item.itemType === ItemType.ImplantCrown || item.itemType === ItemType.BridgeImplant || item.itemType === ItemType.SplintedCrownImplant,
  );

  const [createImplantItem] = useMutation(CreateImplantItemDocument, {
    refetchQueries: ["getOrderByUniqueAttribute"],
    awaitRefetchQueries: true,
  });
  const {data: accountProductPreferences} = useQuery(
    GetAccountProductPreferencesDocument,
  );
  const [deleteManyImplantItem] = useMutation(DeleteManyImplantItemDocument);
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
  } = useForm<ImplantItemFormData>({
    defaultValues: {
      isSplintedCrown: product.implantItem.find((item) => item.itemType === ItemType.SplintedCrownImplant) ? "YES" : "NO",
      retentionType: newImplantItem?.retentionType ?? undefined,
      material: newImplantItem?.itemMaterial?.id ?? undefined,
      shade: newImplantItem?.itemShade?.id ?? undefined,
      isMultiShade: newImplantItem?.teethshadeType === TeethShadeType.MultiShade ? "YES" : "NO",
      gingivalShade: (newImplantItem?.multiShadeInfo ?? []).find((multiShadeInfo: MultiShadeInfo) => multiShadeInfo.teethShadeSide === TeethShadeSide.Gingival)?.itemShade?.id ?? undefined,
      baseShade: (newImplantItem?.multiShadeInfo ?? []).find((multiShadeInfo: MultiShadeInfo) => multiShadeInfo.teethShadeSide === TeethShadeSide.Base)?.itemShade?.id ?? undefined,
      incisalShade: (newImplantItem?.multiShadeInfo ?? []).find((multiShadeInfo: MultiShadeInfo) => multiShadeInfo.teethShadeSide === TeethShadeSide.Incisal)?.itemShade?.id ?? undefined,
      shadeType: findUserPreference(order?.user?.id, accountProductPreferences?.getAccountProductPreferences)?.shadeType ?? FixedProsthesisShadeType.VITA_CLASSIC,
    },
  });

  const watchIsMultiShade = watch("isMultiShade");
  const watchShadeType = watch("shadeType");
  const watchGingivalShade = watch("gingivalShade");
  const watchBaseShade = watch("baseShade");
  const watchIncisalShade = watch("incisalShade");

  const [shadeFormChoices, setShadeFormChoice] = useState(filterShadeByShadeType(implantItemChoicesData?.getItemShadesWhere ?? [], watchShadeType));

  useEffect(() => {
    setShadeFormChoice(filterShadeByShadeType(implantItemChoicesData?.getItemShadesWhere, watchShadeType));
  }, [watchShadeType]);

  useEffect(() => {
    const shadeType = findUserPreference(order?.user?.id, accountProductPreferences?.getAccountProductPreferences)?.shadeType;
    if (shadeType && shadeType !== watchShadeType){
      setValue("shadeType", shadeType);
    }
  }, [accountProductPreferences?.getAccountProductPreferences]);

  if (!product.implantItem || product.implantItem.length <= 0) {
    return (
      <div>
        <Text>
          {"Erreur sur l'ajout de produit. Veuillez contacter le support."}
        </Text>
      </div>
    );
  }

  const materialFormChoices = implantItemChoicesData
    ? formatChoicesData(
        implantItemChoicesData.getItemMaterialsWhere.filter(
          m => m.implantCrown,
        ),
      )
    : [];

  const excludePonticFromBridgeImplantTeeth = (
    bridgeImplantTeeth: number[],
    bridePonticTeeth: number[],
  ) => {
    if (!bridePonticTeeth) {
      return bridgeImplantTeeth;
    }
    return bridgeImplantTeeth.filter(
      (teeth: number) => !bridePonticTeeth.includes(teeth),
    );
  };

  const onSubmit: SubmitHandler<ImplantItemFormData> = async data => {
    try {
      await deleteManyImplantItem({
        variables: {
          where: {
            productId: {
              equals: product.id,
            },
            itemType: {
              in: [
                ItemType.ImplantCrown,
                ItemType.SplintedCrownImplant,
                ItemType.BridgeImplant,
              ],
            },
          },
        },
      });
      if (product.productType === ItemType.BridgeImplant) {
        await createImplantItem({
          variables: {
            args: {
              itemMaterial: {
                connect: {
                  id: data.material,
                },
              },
              itemShade: (data.shade && watchIsMultiShade === "NO")
              ? {
                connect: {
                  id: data.shade,
                },
              } : undefined,
              teeth: excludePonticFromBridgeImplantTeeth(
                product.teeth,
                (product.implantItem ?? []).find(
                  item => item.itemType === ItemType.BridgePontic,
                )?.teeth,
              ),
              itemType: ItemType.BridgeImplant,
              product: {
                connect: {
                  id: product.id,
                },
              },
              retentionType: data.retentionType,
              teethshadeType: watchIsMultiShade === "YES" ? TeethShadeType.MultiShade : TeethShadeType.SingleShade,
              multiShadeInfo: watchIsMultiShade === "YES"
                ? {
                  createMany: {
                    data: [
                      ...data.gingivalShade
                      ? [{
                        itemShadeId: data.gingivalShade,
                        teethShadeSide: TeethShadeSide.Gingival,
                      }] : [],
                      ...data.baseShade
                      ? [{
                        itemShadeId: data.baseShade,
                        teethShadeSide: TeethShadeSide.Base,
                      }] : [],
                      ...data.incisalShade
                      ? [{
                        itemShadeId: data.incisalShade,
                        teethShadeSide: TeethShadeSide.Incisal,
                      }] : [],
                    ],
                  },
                } : undefined,
            },
          },
        });
      } else {
        const allNonPossibleSplintedCrown: number[] = getAllNonSplintedCrown(
          product.teeth,
        );
        const allSplinteCrown: number[][] = getAllPossibleSplintedCrown(
          product.teeth,
        );
        const productOfOrder = [];
        if (data.isSplintedCrown === "YES" && allSplinteCrown.length > 0) {
          allSplinteCrown.map(splintedCrown => {
            productOfOrder.push({
              itemMaterial: {
                connect: {
                  id: data.material,
                },
              },
              itemShade: (data.shade && watchIsMultiShade === "NO")
              ? {
                connect: {
                  id: data.shade,
                },
              } : undefined,
              teeth: splintedCrown,
              itemType: ItemType.SplintedCrownImplant,
              product: {
                connect: {
                  id: product.id,
                },
              },
              retentionType: data.retentionType,
              teethshadeType: watchIsMultiShade === "YES" ? TeethShadeType.MultiShade : TeethShadeType.SingleShade,
              multiShadeInfo: watchIsMultiShade === "YES"
                ? {
                  createMany: {
                    data: [
                      ...data.gingivalShade
                      ? [{
                        itemShadeId: data.gingivalShade,
                        teethShadeSide: TeethShadeSide.Gingival,
                      }] : [],
                      ...data.baseShade
                      ? [{
                        itemShadeId: data.baseShade,
                        teethShadeSide: TeethShadeSide.Base,
                      }] : [],
                      ...data.incisalShade
                      ? [{
                        itemShadeId: data.incisalShade,
                        teethShadeSide: TeethShadeSide.Incisal,
                      }] : [],
                    ],
                  },
                } : undefined,
            });
          });
        }
        if (
          data.isSplintedCrown === "NO" ||
          allNonPossibleSplintedCrown.length > 0
        ) {
          productOfOrder.push({
            itemMaterial: {
              connect: {
                id: data.material,
              },
            },
            itemShade: (data.shade && watchIsMultiShade === "NO")
            ? {
              connect: {
                id: data.shade,
              },
            } : undefined,
            teeth:
              data.isSplintedCrown === "YES"
                ? allNonPossibleSplintedCrown
                : product.teeth,
            itemType: ItemType.ImplantCrown,
            product: {
              connect: {
                id: product.id,
              },
            },
            retentionType: data.retentionType,
            teethshadeType: watchIsMultiShade === "YES" ? TeethShadeType.MultiShade : TeethShadeType.SingleShade,
            multiShadeInfo: watchIsMultiShade === "YES"
              ? {
                createMany: {
                  data: [
                    ...data.gingivalShade
                    ? [{
                      itemShadeId: data.gingivalShade,
                      teethShadeSide: TeethShadeSide.Gingival,
                    }] : [],
                    ...data.baseShade
                    ? [{
                      itemShadeId: data.baseShade,
                      teethShadeSide: TeethShadeSide.Base,
                    }] : [],
                    ...data.incisalShade
                    ? [{
                      itemShadeId: data.incisalShade,
                      teethShadeSide: TeethShadeSide.Incisal,
                    }] : [],
                  ],
                },
              } : undefined,
          });
        }
        await productOfOrder.map(async item => {
          await createImplantItem({
            variables: {
              args: item,
            },
          });
        });
      }
      await updateCreateProductStep(CreateImplantItemStep.Preferences);
    } catch (e) {
      console.error(e);
    }
  };

  if (product.productType === ItemType.BridgeImplant) {
    return (
      <Box>
        <Title order={2}>{"Matériau, teinte et rétention"}</Title>
        <Space h="md" />

        <form onSubmit={handleSubmit(onSubmit)}>
          <BridgeComponent
            materialFormChoices={materialFormChoices ?? []}
            shadeFormChoices={shadeFormChoices ?? []}
            errors={errors}
            control={control}
            watchIsMultiShade={watchIsMultiShade}
            watchBaseShade={watchBaseShade}
            watchIncisalShade={watchIncisalShade}
            watchGingivalShade={watchGingivalShade}
            isMultiShadeEnabled={user?.isMultiShadeEnabled ?? false}
          />
          <StepsButtons
            handleSubmit={() => handleSubmit(onSubmit)()}
            setPreviousAction={updateCreateProductStep}
            previousStep={CreateImplantItemStep.Abutment}
          />
        </form>
      </Box>
    );
  }

  return (
    <Box>
      <Title order={2}>{"Matériau, teinte et rétention"}</Title>
      <Space h="md" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Text
          size="sm"
          style={{fontWeight: "bold"}}
        >
          {"Système de rétention"}
        </Text>
        <Controller
          name="retentionType"
          rules={{required: true}}
          control={control}
          render={({field}) => (
            <ButtonsSelect
              data={RetentionTypeChoices}
              value={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.retentionType?.type === "required" && (
          <Text
            size="sm"
            style={{color: "red"}}
          >
            Veuillez choisir un système de rétention
          </Text>
        )}
        <Space h="md" />
        <Text
          size="sm"
          style={{fontWeight: "bold"}}
        >
          {"Matériau"}
        </Text>
        <Controller
          name="material"
          rules={{required: true}}
          control={control}
          render={({field}) => (
            <ButtonsSelect
              data={materialFormChoices}
              value={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.material?.type === "required" && (
          <Text
            size="sm"
            style={{color: "red"}}
          >
            Veuillez choisir un matériau
          </Text>
        )}
        <Space h="sm" />
        <Text
          size="sm"
          style={{fontWeight: "bold"}}
        >
          {"Type de teintier"}
        </Text>
        <Controller
          name="shadeType"
          rules={{required: true}}
          control={control}
          render={({field}) => (
            <ButtonsSelect
              data={Object.keys(FixedProsthesisShadeType).map((shadeType) => ({ value: shadeType, label: shadeType.replaceAll("_", " ") }))}
              value={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.shadeType?.type === "required" && (
          <Text
            size="sm"
            style={{color: "red"}}
          >
            Veuillez choisir un type de teintier
          </Text>
        )}
        <Space h="md" />
        {user?.isMultiShadeEnabled &&
          <>
            <Text
              size="sm"
              style={{fontWeight: "bold"}}
            >
              {"Affiner l'esthétique avec un dégradé de teinte ?"}
            </Text>
            <Controller
              name="isMultiShade"
              rules={{required: true}}
              control={control}
              render={({field}) => (
                <ButtonsSelect
                  data={yesNoChoices}
                  value={field.value}
                  onChange={value => field.onChange(value)}
                />
              )}
            />
            <Space h="sm" />
          </>
        }
        {watchIsMultiShade === "YES" ? (
          <MultiShadeForm
            control={control}
            shadeFormChoices={shadeFormChoices}
            errors={errors}
            watchBaseShade={watchBaseShade}
            watchIncisalShade={watchIncisalShade}
            watchGingivalShade={watchGingivalShade}
            watchIsMultiShade={watchIsMultiShade}
          />
        ) : (
          <>
            <Text
              size="sm"
              style={{fontWeight: "bold"}}
            >
              {"Teinte"}
            </Text>
            <Controller
              name="shade"
              rules={{required: true}}
              control={control}
              render={({field}) => (
                <Select
                  {...field}
                  data={shadeFormChoices}
                  placeholder="Choisissez une teinte"
                  data-testid="select-implant-shade"
                />
              )}
            />
            {errors?.shade?.type === "required" && (
              <Text
                size="sm"
                style={{color: "red"}}
              >
                Veuillez choisir une teinte
              </Text>
            )}
          </>
        )}
        {getAllPossibleSplintedCrown(product.teeth).length > 0 && (
          <>
            <Space h="md" />
            <Text
              size="sm"
              style={{fontWeight: "bold"}}
            >
              {"Faut-il solidariser les couronnes ?"}
            </Text>
            <Controller
              name="isSplintedCrown"
              rules={{required: false}}
              control={control}
              render={({field}) => (
                <ButtonsSelect
                  data={yesNoChoices}
                  value={field.value}
                  onChange={value => field.onChange(value)}
                />
              )}
            />
          </>
        )}
        <Space h="xl" />
        <StepsButtons
          handleSubmit={() => handleSubmit(onSubmit)()}
          setPreviousAction={updateCreateProductStep}
          previousStep={CreateImplantItemStep.Abutment}
        />
      </form>
    </Box>
  );
};
