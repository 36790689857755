import {Global} from "@mantine/emotion";
import React from "react";
import DM_Sans_Regular from "/@/assets/fonts/DM_Sans/DMSans-Regular.ttf";
import DM_Sans_Bold from "/@/assets/fonts/DM_Sans/DMSans-Bold.ttf";
import DM_Sans_Medium from "/@/assets/fonts/DM_Sans/DMSans-Medium.ttf";
import DM_Sans_Italic from "/@/assets/fonts/DM_Sans/DMSans-Italic.ttf";
import DM_Sans_Bold_Italic from "/@/assets/fonts/DM_Sans/DMSans-BoldItalic.ttf";
import DM_Sans_Medium_Italic from "/@/assets/fonts/DM_Sans/DMSans-MediumItalic.ttf";

export function CustomFonts() {
  return (
    <Global
      styles={[
        {
          "@font-face": {
            fontFamily: "DM Sans",
            src: `url('${DM_Sans_Regular}') format("ttf")`,
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "DM Sans",
            src: `url('${DM_Sans_Medium}') format("ttf")`,
            fontWeight: 500,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "DM Sans",
            src: `url('${DM_Sans_Bold}') format("ttf")`,
            fontWeight: 700,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "DM Sans",
            src: `url('${DM_Sans_Italic}') format("ttf")`,
            fontWeight: 400,
            fontStyle: "italic",
          },
        },
        {
          "@font-face": {
            fontFamily: "DM Sans",
            src: `url('${DM_Sans_Medium_Italic}') format("ttf")`,
            fontWeight: 500,
            fontStyle: "italic",
          },
        },
        {
          "@font-face": {
            fontFamily: "DM Sans",
            src: `url('${DM_Sans_Bold_Italic}') format("ttf")`,
            fontWeight: 700,
            fontStyle: "italic",
          },
        },
      ]}
    />
  );
}
