export enum CreateOrderStep {
  Patient,
  Product,
  Review,
  Placed,
}

export enum CreateAnatomyItemStep {
  Item,
  Preferences,
  Review,
}

export enum CreateImplantItemStep {
  ImplantReference,
  Abutment,
  Item,
  Preferences,
  Review,
}

export enum CreateRemovableItemSplintStep {
  Item,
  Review,
}

export enum CreateRemovableItemPartialDentureStep {
  TeethToManufacture,
  Item,
  Clasps,
  Workflow,
  Review,
}

export enum CreateRemovableItemFullDentureStep {
  Typology,
  Item,
  Review,
}
