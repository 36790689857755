import React, {FC, useEffect} from "react";
import {Box, Select, Space, Text, Button} from "@mantine/core";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {
  GetAccountProductPreferencesDocument,
  GetAccountProductPreferencesQuery,
  ItemType,
  UpdateFixedProsthesisPreferencesDocument,
} from "/@/generated/graphql";
import {
  AnteriorPosteriorPonticDesignLabels,
  InterproximalContactDesignLabels,
  NoOcclusalClearanceLabels,
  OcclusalContactTightnessLabels,
  OcclusalStainingLabels,
  ProximalContactTightnessLabels,
} from "/@/shared/types/product-preferences/fixedProsthesisPreferences.types";
import {useMutation} from "@apollo/client";
import client from "/@/apolloClient";
import {notifications} from "@mantine/notifications";

type UpdateFixedProsthesisPreferencesData = {
  interproximalContactDesign: string;
  noOcclusalClearance: string;
  occlusalContactTightness: string;
  occlusalStaining: string;
  proximalContactTightness: string;
  anteriorPosteriorPonticDesign?: string;
};

type CreateOrderPatientInfoProps = {
  selectedPreferences: NonNullable<
    GetAccountProductPreferencesQuery["getAccountProductPreferences"][0]["fixedProsthesisPreferences"]
  >[0];

  itemType: ItemType;
};

export const UpdateFixedProsthesisPreferences: FC<
  CreateOrderPatientInfoProps
> = ({selectedPreferences, itemType}) => {
  const [updateFixedProsthesisPreferencesMutation] = useMutation(
    UpdateFixedProsthesisPreferencesDocument,
  );
  const {control, reset, handleSubmit} =
    useForm<UpdateFixedProsthesisPreferencesData>({
      defaultValues: selectedPreferences ?? {},
    });
  const onSubmit: SubmitHandler<
    UpdateFixedProsthesisPreferencesData
  > = data => {
    updateFixedProsthesisPreferencesMutation({
      variables: {
        where: {
          id: selectedPreferences?.id,
        },
        data: {
          anteriorPosteriorPonticDesign:
            data.anteriorPosteriorPonticDesign !==
            selectedPreferences.anteriorPosteriorPonticDesign
              ? {
                  set: data.anteriorPosteriorPonticDesign,
                }
              : undefined,
          interproximalContactDesign:
            data.interproximalContactDesign !==
            selectedPreferences.interproximalContactDesign
              ? {
                  set: data.interproximalContactDesign,
                }
              : undefined,
          noOcclusalClearance:
            data.noOcclusalClearance !== selectedPreferences.noOcclusalClearance
              ? {
                  set: data.noOcclusalClearance,
                }
              : undefined,
          occlusalContactTightness:
            data.occlusalContactTightness !==
            selectedPreferences.occlusalContactTightness
              ? {
                  set: data.occlusalContactTightness,
                }
              : undefined,
          occlusalStaining:
            data.occlusalStaining !== selectedPreferences.occlusalStaining
              ? {
                  set: data.occlusalStaining,
                }
              : undefined,
          proximalContactTightness:
            data.proximalContactTightness !==
            selectedPreferences.proximalContactTightness
              ? {
                  set: data.proximalContactTightness,
                }
              : undefined,
        },
      },
    })
      .then(() => {
        notifications.show({
          color: "green",
          title: "Préférences mises à jour",
          message: "",

          styles: theme => ({
            root: {
              backgroundColor: theme.colors.green[7],
              borderColor: theme.colors.green[7],
              "&::before": {backgroundColor: theme.white},
            },
            title: {color: theme.white},
            description: {color: theme.white},
            closeButton: {
              color: theme.white,
              "&:hover": {backgroundColor: theme.colors.green[7]},
            },
          }),
        });
      })
      .then(() => {
        client.refetchQueries({
          include: [GetAccountProductPreferencesDocument],
        });
      });
  };

  useEffect(() => {
    reset(selectedPreferences);
  }, [selectedPreferences]);

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="occlusalContactTightness"
          control={control}
          render={({field}) => (
            <Select
              {...field}
              data={OcclusalContactTightnessLabels}
              label="Occlusion"
            />
          )}
        />
        <Text
          size="xs"
          style={{fontStyle: "italic"}}
        >
          {
            "Si vous utilisiez un papier d'occlusion, combien de marques souhaiteriez vous voir ?"
          }
        </Text>
        <Space h="sm" />
        <Controller
          name="proximalContactTightness"
          control={control}
          render={({field}) => (
            <Select
              {...field}
              data={ProximalContactTightnessLabels}
              label="Contact proximal"
            />
          )}
        />
        <Text
          size="xs"
          style={{fontStyle:"italic"}}
        >
          {"Quelle intensité de contact proximal ?"}
        </Text>
        <Space h="sm" />
        <Controller
          name="occlusalStaining"
          control={control}
          render={({field}) => (
            <Select
              {...field}
              data={OcclusalStainingLabels}
              label="Coloration occlusale"
            />
          )}
        />
        <Text
          size="xs"
          style={{fontStyle: "italic"}}
        >
          {"Quelles sont vos préférences de coloration occlusale ?"}
        </Text>
        <Space h="sm" />
        <Controller
          name="noOcclusalClearance"
          control={control}
          render={({field}) => (
            <Select
              {...field}
              data={NoOcclusalClearanceLabels}
              label="Que doit faire Jasper en cas d'espace occlusal insuffisant ?"
            />
          )}
        />
        <Space h="sm" />
        <Controller
          name="interproximalContactDesign"
          control={control}
          render={({field}) => (
            <Select
              {...field}
              data={InterproximalContactDesignLabels}
              label="Étendue du point de contact inter dentaire"
            />
          )}
        />
        {itemType === ItemType.Bridge && (
          <>
            <Space h="sm" />
            <Controller
              name="anteriorPosteriorPonticDesign"
              control={control}
              render={({field}) => (
                <Select
                  {...field}
                  data={AnteriorPosteriorPonticDesignLabels}
                  label="Design du pontique antérieur et postérieur"
                />
              )}
            />
          </>
        )}
        <Space h="md" />
        <Box
          display="flex"
          style={{justifyContent: "end"}}
        >
          <Button onClick={() => handleSubmit(onSubmit)()}>
            {"Enregistrer"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
