import {useMutation, useQuery, gql} from "@apollo/client";
import {
  Box,
  Button,
  Collapse,
  Grid,
  Loader,
  Space,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, {FC, useState} from "react";
import {useForm} from "react-hook-form";
import {useAuthContext} from "/@/shared/contexts/AuthContext";
import {graphql} from "/@/generated";
import {notifications} from "@mantine/notifications";

// eslint-disable-next-line
const getDentistMainAddressSettings = graphql(`
  query GetDentistMainAddress {
    getDentistMainAddress {
      id
      recipient
      streetNumber
      streetName
      city
      postcode
      country
      name
    }
  }
`);

// eslint-disable-next-line
const updateDentistMainAddressSettings = graphql(`
  mutation UpdateDentistMainAddressSettings(
    $data: AddressUpdateInput!
    $where: AddressWhereUniqueInput!
  ) {
    updateAddress(data: $data, where: $where) {
      id
    }
  }
`);

// eslint-disable-next-line
const createDentistAddress = gql(`
  mutation createDentisteAddress(
    $args: AddressCreateInput!
  ){
    createAddress(args: $args){
      id
    }
  }
`);

type UpdateAddressData = {
  streetNumber?: string;
  streetName: string;
  city: string;
  postcode: string;
  name: string;
};

export const AccountMainInfoPage: FC = () => {
  // const {classes} = useStyles();
  const {user} = useAuthContext();
  const [isChangeAddress, setIsChangeAddress] = useState(false);
  const {data: dentistMainAddress, loading: dentistMainAddressLoading} =
    useQuery(getDentistMainAddressSettings);
  const [updateAddress] = useMutation(updateDentistMainAddressSettings, {
    refetchQueries: ["GetDentistMainAddress"],
    awaitRefetchQueries: true,
  });
  const [createAddress] = useMutation(createDentistAddress, {
    refetchQueries: ["GetDentistMainAddress"],
    awaitRefetchQueries: true,
  });
  const {register, handleSubmit} = useForm<UpdateAddressData>({
    defaultValues: {
      streetNumber:
        dentistMainAddress?.getDentistMainAddress?.streetNumber ?? "",
      streetName: dentistMainAddress?.getDentistMainAddress?.streetName ?? "",
      city: dentistMainAddress?.getDentistMainAddress?.city ?? "",
      postcode: dentistMainAddress?.getDentistMainAddress?.postcode ?? "",
      name: dentistMainAddress?.getDentistMainAddress?.name ?? "",
    },
  });
  if (dentistMainAddressLoading) {
    return <Loader />;
  }

  const onSubmit = (data: UpdateAddressData) => {
    if (dentistMainAddress?.getDentistMainAddress?.id) {
      updateAddress({
        variables: {
          data: {
            streetNumber: {set: data.streetNumber},
            streetName: {set: data.streetName},
            city: {set: data.city},
            postcode: {set: data.postcode},
          },
          where: {
            id: dentistMainAddress?.getDentistMainAddress.id,
          },
        },
      })
        .then(() => {
          notifications.show({
            color: "green",
            message: "Adresse modifiée avec succès",
          });
        })
        .finally(() => setIsChangeAddress(false));
    } else {
      createAddress({
        variables: {
          args: {
            streetNumber: data.streetNumber,
            streetName: data.streetName,
            city: data.city,
            postcode: data.postcode,
            name: data.name,
            user: {
              connect: {
                id: user?.id,
              },
            },
          },
        },
      })
        .then(() => {
          notifications.show({
            color: "green",
            message: "Adresse modifiée avec succès",
          });
        })
        .finally(() => setIsChangeAddress(false));
    }
  };
  return (
    <Box m="xl">
      <Title order={4}>Mon profil</Title>
      <Space h="md" />
      <Grid>
        <Grid.Col span={3}>
          <Text>Praticien</Text>
        </Grid.Col>
        <Grid.Col span={9}>
          <TextInput
            style={{pointerEvents: "none"}}
            readOnly
            value={"Dr. " + user?.firstName + " " + user?.lastName}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Text>Email</Text>
        </Grid.Col>
        <Grid.Col span={9}>
          <TextInput
            style={{pointerEvents: "none"}}
            readOnly
            value={user?.email}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Text>Telephone</Text>
        </Grid.Col>
        <Grid.Col span={9}>
          <TextInput
            readOnly
            style={{pointerEvents: "none"}}
            value={user?.phoneNumber}
            disabled
          />
        </Grid.Col>
      </Grid>
      <Space h="md" />
      <Title order={4}>Mon addresse</Title>
      <Space h="md" />
      <Grid>
        <Grid.Col span={3}>
          <Text>Adresse Principale</Text>
        </Grid.Col>
        <Grid.Col span={9}>
          <TextInput
            style={{pointerEvents: "none"}}
            readOnly
            value={
              dentistMainAddress
                ? dentistMainAddress?.getDentistMainAddress.streetNumber +
                  " " +
                  dentistMainAddress?.getDentistMainAddress.streetName +
                  ", " +
                  dentistMainAddress?.getDentistMainAddress.postcode +
                  " " +
                  dentistMainAddress?.getDentistMainAddress.city
                : ""
            }
          />
        </Grid.Col>
      </Grid>
      <Space h="md" />

      {isChangeAddress !== true && (
        <Box style={{display: "flex", justifyContent: "end"}}>
          <Button onClick={() => setIsChangeAddress(true)}>
            Modifier mon addresse
          </Button>
        </Box>
      )}
      <Collapse in={isChangeAddress}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            {...register("name", {required: true})}
            label="Nom"
            size="sm"
            required={true}
          />
          <TextInput
            {...register("streetNumber", {required: true})}
            label="Numéro de rue"
            size="sm"
            required={true}
          />
          <TextInput
            {...register("streetName", {required: true})}
            label="Nom de rue"
            size="sm"
            required={true}
          />
          <TextInput
            {...register("city", {required: true})}
            label="Ville"
            size="md"
            required={true}
          />
          <TextInput
            {...register("postcode", {required: true})}
            label="Code postal"
            size="md"
            required={true}
          />
          <Space h="md" />
          <Box style={{display: "flex", justifyContent: "end"}}>
            <Button type="submit">Modifier</Button>
            <Button
              color="greyScale.5"
              ml="md"
              onClick={() => setIsChangeAddress(false)}
            >
              Annuler
            </Button>
          </Box>
        </form>
      </Collapse>
    </Box>
  );
};

// const useStyles = createUseStyles((theme) => ({
//   inputLine: {
//     display: 'flex',

// })
