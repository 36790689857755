import React, {FC} from "react";
import {Box, Select, Space, Button} from "@mantine/core";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {
  GetAccountProductPreferencesDocument,
  UpdateProductPreferencesDocument,
  CreateProductPreferencesDocument,
} from "/@/generated/graphql";
import {
  ItemShadeTypeLabel,
  ItemShadeTypeEnum,
} from "/@/shared/types/product-preferences/fixedProsthesisPreferences.types";
import {useMutation} from "@apollo/client";
import client from "/@/apolloClient";
import {notifications} from "@mantine/notifications";
import {generalPreferencesEnum} from "/@/shared/types/product-preferences/productPreferences.types";

type UpdateGeneralProductPreferencesData = {
  shadeType: any;
};

type UpdateGeneralProductPreferencesProps = {
  selectedPreferences: any;
  preferenceType: any;
};

export const UpdateGeneralProductPreferences: FC<
  UpdateGeneralProductPreferencesProps
> = ({selectedPreferences, preferenceType, selectedDentistId}) => {

  const [updateProductPreferencesMutation] = useMutation(
    UpdateProductPreferencesDocument,
  );
  const [createProductPreferencesDocument] = useMutation(
    CreateProductPreferencesDocument,
  );

  const {control, handleSubmit, setValue} =
    useForm<UpdateGeneralProductPreferencesData>({
      defaultValues: {
        shadeType:selectedPreferences?.shadeType,
      },
    });

  const onSubmit: SubmitHandler<
    UpdateGeneralProductPreferencesData
  > = async data => {
    try {
      if (selectedPreferences) {
        await updateProductPreferencesMutation({
          variables: {
            where: {
              id: selectedPreferences.id,
            },
            data: {
              shadeType: data.shadeType ? {set: data.shadeType} : undefined,
            },
          },
        });
      } else {
        await createProductPreferencesDocument({
          variables: {
            args: {
              dentist: {
                connect: {
                  id: selectedDentistId,
                },
              },
              shadeType: data.shadeType ?? undefined,
              productPreferenceType: preferenceType,
            },
          },
        });
      }
      await notifications.show({
        color: "green",
        title: "Préférences mises à jour",
        message: "",

        styles: theme => ({
          root: {
            backgroundColor: theme.colors.green[7],
            borderColor: theme.colors.green[7],
            "&::before": {backgroundColor: theme.white},
          },
          title: {color: theme.white},
          description: {color: theme.white},
          closeButton: {
            color: theme.white,
            "&:hover": {backgroundColor: theme.colors.green[7]},
          },
        }),
      });
      await client.refetchQueries({
        include: [GetAccountProductPreferencesDocument],
      });
    } catch (e) {
      console.error(e);
    }
  };


  React.useEffect(() => {
    setValue("shadeType", selectedPreferences?.shadeType);
  }, [selectedPreferences?.shadeType]);

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="shadeType"
          control={control}
          render={({field}) => (
            <Select
              {...field}
              data={
                preferenceType ===
                generalPreferencesEnum.FIXED_PROSTHESIS_PREFERENCES
                  ? ItemShadeTypeLabel.filter(
                      shade =>
                        shade.value !== ItemShadeTypeEnum.SR_VIVODENT_SPE,
                    )
                  : ItemShadeTypeLabel.filter(
                      shade => shade.value !== ItemShadeTypeEnum.VITA_3D_MASTER,
                    )
              }
              label={
                preferenceType ===
                generalPreferencesEnum.FIXED_PROSTHESIS_PREFERENCES
                  ? "Préférence de teinte fixe"
                  : "Préférence de teinte amobible"
              }
              placeholder={`Choisissez une préférence de teinte ${preferenceType === generalPreferencesEnum.FIXED_PROSTHESIS_PREFERENCES ? "fixe" : "amovible"}`}
            />
          )}
        />
        <Space h="md" />
        <Box
          display="flex"
          style={{justifyContent: "end"}}
        >
          <Button onClick={() => handleSubmit(onSubmit)()}>
            {"Enregistrer"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
