export const getAddressLabelFirstLine = (newOrder: any): string => {
  if (newOrder?.address) {
    return `${newOrder?.address?.streetNumber} ${newOrder?.address?.streetName}`;
  }
  return "Adresse non renseignée";
};

export const getAddressLabelSecondLine = (newOrder: any): string => {
  if (newOrder?.address) {
    return `${newOrder?.address?.postcode} ${newOrder?.address?.city}`;
  }
  return "";
};
