import {Box, Button} from "@mantine/core";
import {createStyles} from "@mantine/emotion";
import React from "react";

type StepsButtonsProps = {
  handleSubmit: any;
  setPreviousAction: (data: any) => void;
  previousStep?: number;
  previousLabel?: string;
  nextLabel?: string;
  isFirst?: boolean;
  isLast?: boolean;
  isValidateStyle?: boolean;
};

export const StepsButtons: React.FC<StepsButtonsProps> = ({
  handleSubmit,
  setPreviousAction,
  previousStep,
  previousLabel = "Précédent",
  nextLabel = "Suivant",
  isFirst = false,
  isLast = false,
  isValidateStyle = false,
  applyBeforePreviousStep = undefined,
}) => {
  const {classes} = useStyles({isFirst, isLast});
  return (
    <Box className={classes.container}>
      {!isFirst && (
        <Button
          variant="outline"
          size="md"
          onClick={async() => {
            if (applyBeforePreviousStep){
              await applyBeforePreviousStep();
            }
            previousStep !== undefined ? setPreviousAction(previousStep) : null;
          }}
          data-testid="step-button-not-first"
        >
          {previousLabel}
        </Button>
      )}
      {!isLast && (
        <Button
          size={isValidateStyle ? "lg" : "md"}
          color={isValidateStyle ? "green" : undefined}
          onClick={() => {
            handleSubmit();
          }}
          data-testid="step-button-not-last"
        >
          {nextLabel}
        </Button>
      )}
    </Box>
  );
};

type stepsButtonsStylesProps = {
  isFirst: boolean;
  isLast: boolean;
};

const useStyles = createStyles(
  (theme, {isFirst, isLast}: stepsButtonsStylesProps) => ({
    container: {
      display: "flex",
      justifyContent: isFirst
        ? "flex-end"
        : isLast
        ? "flex-start"
        : "space-between",
    },
  }),
);
