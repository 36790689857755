import React, {FC} from "react";
import {
  GetAnatomyItemChoicesQuery,
  GetOrderByUniqueAttributeQuery,
  GetUserProductPreferencesQuery,
} from "/@/generated/graphql";
import {CreateOrderAnatomyItemPreferences} from "./CreateOrderAnatomyPreferences";
import {CreateAnatomyItemStep} from "../../../../shared/types/orders/createOrder.types";
import {CreateOrderAnatomyItemInfo} from "./CreateOrderAnatomyItemInfo";
import { Grid, Space, Stepper} from "@mantine/core";
import {createStyles} from "@mantine/emotion";
import {CreateProductSummarySideBar} from "../CreateProductSummarySideBar";
import {useCreateOrderStore} from "/@/shared/stores/orders/create-store.store";

type CreateOrderAnatomyItemsProps = {
  product: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
  >[0];
  allProducts: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
  >;
  patient: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["patient"];
  userProductPreferences: GetUserProductPreferencesQuery;
  anatomyItemChoicesData: GetAnatomyItemChoicesQuery;
  order: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"];
};

export const CreateOrderAnatomyItems: FC<CreateOrderAnatomyItemsProps> = ({
  patient,
  product,
  userProductPreferences,
  anatomyItemChoicesData,
  order,
  allProducts,
}) => {
  const createProductStep = useCreateOrderStore(
    state => state.createProductStep,
  );
  const {classes} = useStyles();
  const dataContent = () => {
    switch (createProductStep) {
      case CreateAnatomyItemStep.Item:
        return (
          <CreateOrderAnatomyItemInfo
            product={product}
            anatomyItemChoicesData={anatomyItemChoicesData}
            allProducts={allProducts}
            order={order}
          />
        );
      case CreateAnatomyItemStep.Preferences:
        return (
          <CreateOrderAnatomyItemPreferences
            product={product}
            userProductPreferences={userProductPreferences}
          />
        );
      default:
        return <div>ERROR create anatomy item</div>;
    }
  };

  return (
    <Grid
      columns={36}
      gutter={0}
      style={{height: "100%"}}
    >
      <Grid.Col
        span={createProductStep < CreateAnatomyItemStep.Review ? 26 : 36}
        style={theme => ({padding: theme.spacing.lg, height: "fit-content"})}
      >
        <Stepper
          active={createProductStep}
          classNames={{step: classes.stepper, stepBody: classes.stepBody}}
        >
          <Stepper.Step label="Prothèse" />
          <Stepper.Step label="Préférences" />
        </Stepper>
        <Space h="xl" />
        {dataContent()}
      </Grid.Col>
      {createProductStep !== CreateAnatomyItemStep.Review && (
        <Grid.Col
          span={10}
          style={theme => ({
            padding: theme.spacing.sm,
            backgroundColor: theme.colors.primaryLight[9],
          })}
        >
          <CreateProductSummarySideBar
            product={product}
            patient={patient}
            order={order}
          />
        </Grid.Col>
      )}
    </Grid>
  );
};

const useStyles = createStyles(theme => ({
  stepper: {
    flexDirection: "column",
  },
  stepBody: {
    marginLeft: 0,
    marginTop: theme.spacing.xs,
    alignItems: "center",
  },
}));
