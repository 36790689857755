import React, {FC} from "react";
import {
  GetOrderByUniqueAttributeQuery,
  SendOrderRedoRequestDocument,
} from "/@/generated/graphql";
import {useMutation} from "@apollo/client";
import {Box, Button, Flex, Space, Text, Textarea, Title} from "@mantine/core";
import {IconX} from "@tabler/icons-react";
import {notifications} from "@mantine/notifications";

type OrderGetRedoProps = {
  order: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]
  >;
  closeModal?: () => void;
};

export const OrderGetRedo: FC<OrderGetRedoProps> = ({
  order,
  closeModal = () => null,
}) => {
  const [redoRequestMessage, setRedoRequestMessage] = React.useState("");
  const [newDeliveryDate, setNewDeliveryDate] = React.useState(null);
  const [sendOrderRedoRequest, {error}] = useMutation(
    SendOrderRedoRequestDocument,
  );

  const onSubmit = async () => {
    const req = await sendOrderRedoRequest({
      variables: {
        orderId: order.id,
        comment: redoRequestMessage,
      },
    });
    setNewDeliveryDate(
      req?.data?.redoOrder
        ? new Date(req.data.redoOrder).toLocaleString("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "long",
          })
        : "En cours de calcul. Elle vous sera communiquée prochainement",
    );
  };

  if (newDeliveryDate) {
    return (
      <div style={{textAlign: "center"}}>
        <Title order={3}>
          {`Date de livraison estimée : ${newDeliveryDate}`}
        </Title>
        <Space h="md" />
        <Button
          onClick={() => {
            notifications.show({
              color: "green",
              title: "Votre demande a bien été envoyée",
              message: "",
            });
            closeModal();
          }}
          style={{backgroundColor: "green"}}
        >
          Valider
        </Button>
      </div>
    );
  }

  return (
    <Box>
      <Flex
        w="100%"
        justify="right"
      >
        <IconX
          onClick={() => closeModal()}
          style={{cursor: "pointer"}}
          color="black"
        />
      </Flex>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Title
          order={2}
          style={theme=>({color: theme.colors.primary[5]})}
        >
          Demande de refabrication pour la commande:
        </Title>
        <Space h="md" />
        <Title
          order={2}
          style={theme=>({color: theme.colors.primary[5]})}
        >{`${order.orderReference}`}</Title>
        <Space h="md" />
        <Title order={4}>
          Votre conseiller Jasper reviendra vers vous très rapidement.
        </Title>
        <Space h="lg" />
        <Textarea
          color="primary"
          placeholder="Veuillez indiquer ce que vous souhaitez pour la refabrication"
          onChange={event => setRedoRequestMessage(event.target.value)}
          w="80%"
          autosize
          minRows={4}
          maxRows={12}
        />
        <Space h="md" />
        <Button onClick={onSubmit}>Envoyer</Button>

        {error && (
          <>
            <Space h="sm" />
            <Text
              size="sm"
              style={theme=>({color: theme.colors.red[5]})}
            >
              Une erreur est survenue, veuillez contacter votre conseiller
              Jasper directement
            </Text>
          </>
        )}
      </Box>
      <Space h="md" />
    </Box>
  );
};
