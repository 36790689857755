import {useLazyQuery, useMutation} from "@apollo/client";
import React, {FC, useState} from "react";
import {
  AddProductToOrderFromFormDocument,
  GetOrderByUniqueAttributeDocument,
  ItemType,
} from "/@/generated/graphql";
import {CreateOrderAddProductForm} from "../../../components/order/CreateOrderAddProductForm";
import {useCreateOrderStore} from "/@/shared/stores/orders/create-store.store";
import {CreateOrderStep} from "/@/shared/types/orders/createOrder.types";

type CreateOrderAddProductProps = {
  closeModal: () => void;
};

export const CreateOrderAddProduct: FC<CreateOrderAddProductProps> = () => {
  const [teeth, setTeeth] = useState<number[]>([]);
  const [productGroupType, setProductGroupType] = useState<ItemType | null>(
    null,
  );
  const [
    newOrderId,
    createProductIndex,
    updateCreateOrderStep,
    updateCreateProductIndex,
  ] = useCreateOrderStore(state => [
    state.newOrderId,
    state.createProductIndex,
    state.updateCreateOrderStep,
    state.updateCreateProductIndex,
  ]);

  const [addProductToOrder] = useMutation(AddProductToOrderFromFormDocument, {
    refetchQueries: [GetOrderByUniqueAttributeDocument],
    awaitRefetchQueries: true,
  });
  const [getCreatingOrder] = useLazyQuery(GetOrderByUniqueAttributeDocument, {
    variables: {
      where: {
        id: newOrderId,
      },
    },
    fetchPolicy: "network-only",
  });

  const handleSubmit = async () => {
    if (productGroupType && teeth.length > 0 && newOrderId) {
      const teethString = teeth.join(",");
      const productInfo = productGroupType + " " + teethString;
      await addProductToOrder({
        variables: {
          productInfo: productInfo,
          orderId: newOrderId,
        },
      })
        .then(async () => await getCreatingOrder())
        .then(() => {
          //as we add from the review page, index should be at the last previous item, so we need to add one to it as we add a new product
          updateCreateProductIndex(createProductIndex + 1);
          updateCreateOrderStep(CreateOrderStep.Product);
        });
    }
  };

  return (
    <CreateOrderAddProductForm
      teeth={teeth}
      setTeeth={setTeeth}
      productGroupType={productGroupType}
      setProductGroupType={setProductGroupType}
      handleSubmit={handleSubmit}
    />
  );
};
