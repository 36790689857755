import React from "react";
import type {FC} from "react";
import {HashRouter, Navigate, Route, Routes} from "react-router-dom";
import {useAuthContext} from "../shared/contexts/AuthContext";
import {LoginPage} from "/@/pages/login/LoginPage";
import {Dashboard} from "/@/pages/dashboard/Dashboard";
import {CreateOrderFileInterceptors} from "/@/services/ipc/createOrderFile.interceptor";
import {useIpcContext} from "/@/shared/contexts/IpcContext";
import {LoggedInLayout} from "/@/components/layout/LoggedInLayout";
import {OrderPage} from "/@/pages/orders/OrderPage";
import {CreateOrderPage} from "/@/pages/orders/create/CreateOrderPage";
import {CreateOrderFormPage} from "/@/pages/orders/create/CreateOrderFormPage";
import {OrdersListPage} from "/@/pages/orders/OrdersListPage";
import {NotificationsListPage} from "/@/pages/notifications/NotificationsListPage";
import {SettingsPage} from "/@/pages/settings/SettingsPage";
import {UploadFilePage} from "/@/pages/orders/UploadFilePage";
import {io} from "socket.io-client";
import {useNavigate} from "react-router-dom";
import {getApiUrl} from "/@/services/api/api";

export const RoutesDispatcher: FC = () => {
  return (
    <HashRouter>
      <Routes>
        <Route
        path="/order-upload-file/:id/name/:name/token/:token"
        element={
          <UploadFilePage />
        }
        />
        <Route
          path="/login"
          element={
            <CreateOrderRedirect>
              <LoginRedirect>
                <LoginPage />
              </LoginRedirect>
            </CreateOrderRedirect>
          }
        />
        <Route
          path="/create-order"
          element={
            <LoginRequired>
              <CreateOrderPage />
            </LoginRequired>
          }
        />
        <Route
          path="/create-order-form"
          element={
            <LoginRequired>
              <CreateOrderFormPage />
            </LoginRequired>
          }
        />
        <Route
          path="/orders"
          element={
            <LoginRequired>
              <CreateOrderRedirect>
                <OrdersListPage />
              </CreateOrderRedirect>
            </LoginRequired>
          }
        />
        <Route
          path="/notifications"
          element={
            <LoginRequired>
              <CreateOrderRedirect>
                <NotificationsListPage />
              </CreateOrderRedirect>
            </LoginRequired>
          }
        />
        <Route
          path="/order/:id"
          element={
            <LoginRequired>
              <CreateOrderRedirect>
                <OrderPage />
              </CreateOrderRedirect>
            </LoginRequired>
          }
        />
        <Route
          path="/settings"
          element={
            <LoginRequired>
              <CreateOrderRedirect>
                <SettingsPage />
              </CreateOrderRedirect>
            </LoginRequired>
          }
        />
        <Route
          path="/"
          element={
            <LoginRequired>
              <CreateOrderRedirect>
                <Dashboard />
              </CreateOrderRedirect>
            </LoginRequired>
          }
        />
      </Routes>
    </HashRouter>
  );
};

const LoginRequired = ({children}: {children: JSX.Element}) => {
  const {isLoggedIn, user} = useAuthContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (user?.id) {
      const socket = io(getApiUrl(), {
        query: {
          user_id: user?.id,
          type: "order",
        },
      });
      socket.on("new_order", (args: {orderId?: string}) => {
        if (args && args?.orderId) {
          navigate("/create-order", {
            state: {
              id: args?.orderId,
            },
          });
        } else {
          console.error("Err argument websocket", args);
        }
      });
      socket.on("disconnect", (reason) => {
        socket.connect();
      });
    }
  }, [isLoggedIn]);

  return isLoggedIn ? (
    // isCreatingOrder ? (
    //   <Navigate to="/test" />
    // ) : (
    // <CreateOrderFileInterceptors>{children}</CreateOrderFileInterceptors>
    <LoggedInLayout>{children}</LoggedInLayout>
  ) : (
    // )/>
    <Navigate to="/login" />
  );
};

const CreateOrderRedirect = ({children}: {children: JSX.Element}) => {
  const {creatingOrderId} = useIpcContext();
  const {isLoggedIn} = useAuthContext();
  return isLoggedIn &&
    creatingOrderId !== null &&
    typeof creatingOrderId === "string" ? (
    <Navigate
      to="/create-order"
      state={{id: creatingOrderId}}
    />
  ) : (
    <CreateOrderFileInterceptors>{children}</CreateOrderFileInterceptors>
  );
};

const LoginRedirect = ({children}: {children: JSX.Element}) => {
  const {isLoggedIn} = useAuthContext();
  return isLoggedIn ? <Navigate to="/" /> : children;
};
