import {Box, Button} from "@mantine/core";
import React, {ForwardRefRenderFunction, ReactElement, useState} from "react";

type ButtonsSelectProps = {
  value: any;
  numberOfColumns?: number;
  align?: "left" | "center" | "right";
  data: {value: any; label: string | ReactElement}[];
  onChange: (value: any) => void;
};

export const ButtonsSelect: ForwardRefRenderFunction<
  HTMLInputElement,
  ButtonsSelectProps
> = ({data, numberOfColumns = 2, align = "center", value, onChange}) => {
  const [val, setVal] = useState(value);
  return (
    <Box
      style={theme => ({
        display: "grid",
        gridAutoRows: numberOfColumns === 1 ? "minmax(80px, auto)" : undefined,
        // gridRowGap: numberOfColumns === 1 ? theme.spacing.md : undefined,
        gridRowGap: theme.spacing.sm,
        gridTemplateColumns:
          numberOfColumns === 3
            ? "1fr 1fr 1fr"
            : numberOfColumns === 1
            ? "1fr"
            : "1fr 1fr",
        minHeight: "30%",
        minWidth: "30%",
        alignContent: "center",
        justifyContent: "center",
      })}
    >
      {data.map(item => (
        <Button
          key={item.value}
          variant="light"
          onClick={() => {
            onChange(item.value);
            setVal(item.value);
          }}
          data-testid={`button-select-${item.value}`}
          value={val}
          color={item.value === value ? "blue" : "gray"}
          p="xs"
          m="5px"
          style={() => ({
            minHeight: "100%",
            display: "flex",
            border: item.value === value ? "2px solid #228be6" : "0",
            justifyContent: 
              align === "center"
                ? "center"
                : align === "left"
                ? "flex-start"
                : "flex-end",
            textAlign: align,
          })}
        >
          {item.label}
        </Button>
      ))}
    </Box>
  );
};
