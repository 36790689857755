import {
  Box,
  Button,
  Grid,
  Modal,
  Space,
  Text,
  Title,
} from "@mantine/core";
import {createStyles} from "@mantine/emotion";
import {
  GetOrderByUniqueAttributeDocument,
  GetOrderByUniqueAttributeQuery,
  ItemType,
  Order,
  OrderStatus,
} from "/@/generated/graphql";
import React, {FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import DentalArchesSvg from "/@/pages/orders/DentalArchesSvg";
import {OrderStatusStepper} from "/@/pages/orders/OrderStatusStepper";
import {OrderFilesManagement} from "/@/pages/orders/OrderFilesManagement";
import {useQuery} from "@apollo/client";
import {OrderGetHelp} from "/@/pages/orders/OrderGetHelp";
import {OrderGetRedo} from "/@/pages/orders/OrderGetRedo";
import {ItemsDescription} from "/@/components/order/ItemsDescription";

import {
  getAddressLabelFirstLine,
  getAddressLabelSecondLine,
  getOrderFirstProduct,
  getOrderStatusStep,
  getPatientLabelFirstLine,
  getPatientLabelSecondLine,
  getProductTeeth,
  getAllTeeths,
  getAllOrderComments,
  getAllPartialDentureComments,
  getAllRedoComments,
  displayOrderComments,
  displayPartialDentureComments,
} from "@jasper/shared";

export const OrderPage: FC = () => {
  const param = useParams();
  const {classes} = useStyles();

  const [selectedProduct, setSelectedProduct] = useState<
    | NonNullable<
        GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
      >[0]
    | null
  >(null);
  const [
    isOrderFilesManagementModalOpened,
    setIsOrderFilesManagementModalOpened,
  ] = useState(false);
  const [isOrderHelpModalOpened, setIsOrderHelpModalOpened] = useState(false);
  const [isOrderRedoModalOpened, setIsOrderRedoModalOpened] = useState(false);

  const {data, refetch} = useQuery(GetOrderByUniqueAttributeDocument, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        id: param.id,
      },
    },
  });

  useEffect(() => {
    if (data) {
      setSelectedProduct(getOrderFirstProduct(data.getOrderByUniqueAttribute));
    }
  }, [data]);

  if (
    !data ||
    !data.getOrderByUniqueAttribute ||
    !data.getOrderByUniqueAttribute.products ||
    data.getOrderByUniqueAttribute.products.length < 1
  ) {
    return null;
  } else {
    const user = data.getOrderByUniqueAttribute.user;
    const order = data.getOrderByUniqueAttribute;
    const orderDate = new Date(data.getOrderByUniqueAttribute.createdAt);
    let expectedDeliveryDate = null;
    if (data.getOrderByUniqueAttribute.deliveryDate !== null) {
      expectedDeliveryDate = new Date(0);
      expectedDeliveryDate.setUTCMilliseconds(
        data.getOrderByUniqueAttribute.deliveryDate,
      );
    } else {
      expectedDeliveryDate = new Date();
      expectedDeliveryDate.setDate(orderDate.getDate() + 10);
    }

    return (
      <Box
        p="lg"
        style={() => ({
          display: "flex",
          flexDirection: "column",
          height: "100%",
        })}
      >
        <Modal
          centered
          transitionProps={{transition: "fade", duration: 300}}
          withCloseButton={false}
          size="xl"
          opened={isOrderFilesManagementModalOpened}
          onClose={() => setIsOrderFilesManagementModalOpened(false)}
          styles={{
            title: {
              alignContent: "center",
              justifyContent: "center",
            },
          }}
        >
          <OrderFilesManagement
            order={data.getOrderByUniqueAttribute}
            closeModal={() => setIsOrderFilesManagementModalOpened(false)}
          />
        </Modal>
        <Modal
          centered
          transitionProps={{transition: "fade", duration: 300}}
          withCloseButton={false}
          size="xl"
          opened={isOrderHelpModalOpened}
          onClose={() => setIsOrderHelpModalOpened(false)}
          styles={{
            title: {
              alignContent: "center",
              justifyContent: "center",
            },
          }}
        >
          <OrderGetHelp
            order={data.getOrderByUniqueAttribute}
            closeModal={() => setIsOrderHelpModalOpened(false)}
          />
        </Modal>
        <Modal
          centered
          transitionProps={{transition: "fade", duration: 300}}
          withCloseButton={false}
          size="xl"
          opened={isOrderRedoModalOpened}
          onClose={() => setIsOrderRedoModalOpened(false)}
          styles={{
            title: {
              alignContent: "center",
              justifyContent: "center",
            },
          }}
        >
          <OrderGetRedo
            order={data.getOrderByUniqueAttribute}
            closeModal={() => {
              refetch();
              setIsOrderRedoModalOpened(false);
            }}
          />
        </Modal>

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Title
              order={4}
              style={theme =>({color: theme.colors.primary[4]})}
            >
              Commande {order.orderReference}
            </Title>
          </Box>
          <Box style={theme => ({display: "flex", gap: theme.spacing.sm})}>
            <Button
              variant="filled"
              onClick={() => setIsOrderFilesManagementModalOpened(true)}
            >
              Fichiers
            </Button>
            {order.status !== OrderStatus.Delivered && (
              <Button
                variant="outline"
                onClick={() => setIsOrderHelpModalOpened(true)}
              >
                Commentaire
              </Button>
            )}
            {order.status === OrderStatus.Delivered && (
              <Button
                variant="outline"
                onClick={() => setIsOrderRedoModalOpened(true)}
              >
                Refabrication
              </Button>
            )}
          </Box>
        </Box>
        <Space h="sm" />
        <Grid
          gutter="xl"
          style={{flexGrow: 1, minHeight: "fit-content", maxHeight: "30%"}}
        >
          <Grid.Col
            className={classes.gridColContainer}
            span={5}
            style={{height:"inherit"}}
          >
            <Box className={classes.itemsDetails}>
              <Title order={5}>Patient</Title>
              <Text className={classes.boxText}>
                {getPatientLabelFirstLine(order)}
              </Text>
              <Text className={classes.boxText}>
                {getPatientLabelSecondLine(order)}
              </Text>
              <Space h="md" />
              <Title order={5}>Praticien</Title>
              <Text className={classes.boxText}>
                {user.firstName} {user.lastName}
              </Text>
              <Text className={classes.boxText}>
                {getAddressLabelFirstLine(order)}
              </Text>
              <Text className={classes.boxText}>
                {getAddressLabelSecondLine(order)}
              </Text>
            </Box>
          </Grid.Col>
          <Grid.Col
            className={classes.gridColContainer}
            span={7}
            style={{height:"inherit"}}
          >
            <Box className={classes.itemsDetails}>
              <Space h="md" />
              <Title order={5}>
                Date de livraison estimée:{" "}
                {expectedDeliveryDate.toLocaleDateString()}
              </Title>
              <Space h="md" />
              <OrderStatusStepper
                orderStatus={getOrderStatusStep(
                  data.getOrderByUniqueAttribute.status,
                )}
                order={data.getOrderByUniqueAttribute as Order}
                labelsUnder={true}
                grow={true}
              />
            </Box>
          </Grid.Col>
        </Grid>
        <Space h="xl" />
        <Grid
          gutter="xl"
          style={{flexGrow: 1}}
        >
          <Grid.Col
            className={classes.gridColContainer}
            span={5}
            style={{height:"inherit"}}
          >
            <Box
              className={classes.itemsDetails}
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Title
                order={4}
                className={classes.boxTitle}
              >
                Dents du produit sélectionné
              </Title>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {getProductTeeth(getOrderFirstProduct(order)) && (
                  <DentalArchesSvg
                    teeth={
                      selectedProduct?.productType === "PARTIAL_DENTURE" &&
                      selectedProduct?.removableItem &&
                      selectedProduct?.removableItem?.length > 0
                        ? selectedProduct?.teeth
                        : selectedProduct?.removableItem &&
                          selectedProduct?.removableItem?.length > 0 &&
                          selectedProduct?.removableItem[0]?.isBothJaws
                        ? getAllTeeths()
                        : selectedProduct?.teeth
                    }
                    width={275}
                    height={366}
                  />
                )}
              </Box>
            </Box>
          </Grid.Col>
          <Grid.Col
            className={classes.gridColContainer}
            span={7}
            style={{height:"inherit"}}
          >
            <Box
              className={classes.itemsDetails}
              style={{height: "100%"}}
            >
              <Title
                order={4}
                className={classes.boxTitle}
              >
                {`Détail de la commande${
                  order.products && order.products.length > 1
                    ? " (cliquez sur un produit pour le sélectionner)"
                    : ""
                }`}
              </Title>
              <Box>
                <Space h="md" />
                <Box>
                  {order.products &&
                    order.products.map(product => (
                      <Box key={product.id}>
                        <Button
                          variant={
                            selectedProduct?.id === product.id
                              ? "filled"
                              : "outline"
                          }
                          styles={{inner: {justifyContent: "start"}}}
                          color={
                            selectedProduct?.id === product.id
                              ? "primary.5"
                              : undefined
                          }
                          style={theme => ({
                            "&:hover": {
                              backgroundColor:
                                selectedProduct?.id === product.id
                                  ? undefined
                                  : theme.colors.greyScale[9],
                            },
                          })}
                          h="auto"
                          p={8}
                          mb="xs"
                          w="100%"
                          onClick={() => setSelectedProduct(product)}
                        >
                          <ItemsDescription
                            key={product.id}
                            product={product}
                          />
                        </Button>
                        {selectedProduct &&
                          selectedProduct.id === product.id &&
                          selectedProduct?.removableItem &&
                          selectedProduct.removableItem[0] &&
                          selectedProduct.removableItem[0].itemType ===
                            ItemType.PartialDenture &&
                          getAllPartialDentureComments(order).length > 0 && (
                            <>
                              <Text size="sm">
                                Commentaire sur les crochets: {displayPartialDentureComments(order)}
                              </Text>
                              <Space h="sm" />
                            </>
                          )}
                      </Box>
                    ))}
                </Box>
                <Space h="xl" />
                <Title order={5}>Notes du praticien sur la commande</Title>
                <Text size="sm">
                  {(getAllOrderComments(order)).length > 0
                    ? displayOrderComments(order)
                    : "Pas de note pour cette commande"}
                </Text>
                {(getAllRedoComments(order)).length > 0 && (
                  <>
                    <Space h="xl" />
                    <Title order={5}>
                      Notes du praticien sur la demande de refabrication de
                      cette commande
                    </Title>
                    <ul>
                      {(getAllRedoComments(order)).map(redo => {
                        return (
                          <Text size="sm">
                            {redo?.comment ? (
                              <li>
                                <span style={{fontWeight: "bold"}}>
                                  {new Date(
                                    redo?.createdAt,
                                  ).toLocaleDateString()}
                                </span>
                                : {redo?.comment}
                              </li>
                            ) : (
                              ""
                            )}
                          </Text>
                        );
                      })}
                    </ul>
                  </>
                )}
              </Box>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
    );
  }
};

const useStyles = createStyles((theme) => ({
  gridColContainer: {height: "100%"},
  itemsDetails: {
    borderRadius: "10px",
    outline: `1px solid ${theme.colors.greyScale[9]}`,
    padding: theme.spacing.md,
    height: "100%",
    minHeight: "100%",
    minWidth: "100%",
  },
  boxTitle: {
    color: theme.colors.primary[4],
  },
  boxText: {
    fontSize: theme.fontSizes.sm,
  },
}));
