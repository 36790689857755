import { createWithEqualityFn as create } from "zustand/traditional";

import {CreateOrderStep} from "/@/shared/types/orders/createOrder.types";

type CreateOrderStoreState = {
  createOrderStep: CreateOrderStep;
  newOrderId: string | null;
  createProductStep: number;
  newProductId: string | null;
  createProductIndex: number;
  updateCreateOrderStep: (step: CreateOrderStep) => void;
  updateNewOrderId: (id: string) => void;
  updateCreateProductStep: (step: number) => void;
  updateNewProductId: (id: string) => void;
  updateCreateProductIndex: (index: number) => void;
  incrementCreateProductIndex: () => void;
};

export const useCreateOrderStore = create<CreateOrderStoreState>()(set => ({
  createProductStep: 0,
  createOrderStep: CreateOrderStep.Patient,
  newOrderId: null,
  newProductId: null,
  createProductIndex: 0,
  updateCreateOrderStep: (step: CreateOrderStep) =>
    set({createOrderStep: step}),
  updateNewOrderId: (id: string) => set({newOrderId: id}),
  updateCreateProductStep: (step: number) => set({createProductStep: step}),
  updateNewProductId: (id: string) => set({newProductId: id}),
  updateCreateProductIndex: (index: number) => set({createProductIndex: index}),
  incrementCreateProductIndex: () =>
    set(state => ({createProductIndex: state.createProductIndex + 1})),
}));
