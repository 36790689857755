import {ItemType} from "/@/generated/graphql";

export const productPreferencesItemTypes = [
  {value: ItemType.Crown, label: "Couronne"},
  {value: ItemType.Bridge, label: "Bridge"},
  {value: ItemType.Lay, label: "Lay"},
  {value: ItemType.Implant, label: "Implant"},
  {value: ItemType.Veneer, label: "Facette"},
];

export enum generalPreferencesEnum {
  FIXED_PROSTHESIS_PREFERENCES = "FIXED_PROSTHESIS_PREFERENCES",
  REMOVABLE_PROSTHESIS_PREFERENCES = "REMOVABLE_PROSTHESIS_PREFERENCES",
}

export const generalPreferences = [
  {
    value: generalPreferencesEnum.FIXED_PROSTHESIS_PREFERENCES,
    label: "Teintier pour prothèse fixe",
  },
  {
    value: generalPreferencesEnum.REMOVABLE_PROSTHESIS_PREFERENCES,
    label: "Teintier pour prothèse amovible",
  },
];
