import { gql } from "@apollo/client";

export const GET_FILE_DOWNLOAD_URL = gql(`
  query GetPresignedFileDownloadUrl($key: String!) {
    getPresignedFileDownloadUrl(key: $key)
  }
`);

export const GET_FILE_UPLOAD_URL = gql(`
  query Query($fileName: String!) {
    getPresignedFileUploadUrl(fileName: $fileName)
  }
`);

export const GetFilesOfOrderDocument = gql(`
  query GetFilesOfOrder($where: FileWhereInput) {
    getFilesOfOrder(where: $where) {
      chat {
        id
        orderId
        userGroupId
      }
      fileType
      key
      keyCompressed
      userId
      id
    }
  }
`);

export const CreateFileDocument = gql(`
  mutation CreateFile($args: FileCreateInput!) {
    createFile(args: $args) {
      key
      keyCompressed
      orderId
      userId
      fileType
      id
      chatId
    }
  }
`);

export const DeleteFileDocument = gql(`
  mutation DeleteFile($key: String!, $where: FileWhereUniqueInput!) {
    deleteFile(key: $key, where: $where) {
      key
    }
  }
`);
