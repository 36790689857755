import {OrderStatus} from "/@/generated/graphql";

export const OrderStatusLabels = [
  {
    value: OrderStatus.Draft,
    label: "Brouillon",
  },
  {
    value: OrderStatus.Confirmed,
    label: "Confirmée",
  },
  {
    value: OrderStatus.Producing,
    label: "En fabrication",
  },
  {
    value: OrderStatus.Shipped,
    label: "Expédiée",
  },
  {
    value: OrderStatus.Delivered,
    label: "Livrée",
  },
];

export const ConfirmedOrderStatusLabels = [
  {
    value: OrderStatus.Confirmed,
    label: "Confirmée",
  },
  {
    value: OrderStatus.WaitingForProduction,
    label: "En attente de fabrication",
  },
  {
    value: OrderStatus.Producing,
    label: "En fabrication",
  },
  {
    value: OrderStatus.Shipped,
    label: "Expédiée",
  },
  {
    value: OrderStatus.Delivered,
    label: "Livrée",
  },
];
