import {
  Box,
  Button,
  Grid,
  Space,
  Text,
  Title,
} from "@mantine/core";
import {createStyles} from "@mantine/emotion";
import React, {FC, useEffect, useState} from "react";
import {ButtonsSelect} from "/@/components/ButtonsSelect";
import {ItemType} from "/@/generated/graphql";
import DentalArchesSvg from "/@/pages/orders/DentalArchesSvg";
import {
  LowerArchTeeth,
  UpperArchTeeth,
} from "../../shared/types/products/products.types";
import {
  AddProdctToOrderFormItemTypeChoices,
  isUpperArchTeeth,
} from "/@/shared/utils/products/products.utils";

import {
  isRemovableItemType,
} from "@jasper/shared";

type CreateOrderAddProductFormProps = {
  teeth: number[];
  productGroupType: ItemType | null;
  setTeeth: React.Dispatch<React.SetStateAction<number[]>>;
  setProductGroupType: React.Dispatch<React.SetStateAction<ItemType | null>>;
  handleSubmit: () => void;
};

export const CreateOrderAddProductForm: FC<CreateOrderAddProductFormProps> = ({
  teeth,
  productGroupType,
  setTeeth,
  setProductGroupType,
  handleSubmit,
}) => {
  const {classes} = useStyles();

  const [isFormError, setIsFormError] = useState(false);

  useEffect(() => {
    if (productGroupType) {
      setTeeth([]);
    }
  }, [productGroupType]);

  const onSubmit = () => {
    if (!productGroupType || teeth.length <= 0) {
      setIsFormError(true);
      return;
    } else {
      setIsFormError(false);
      handleSubmit();
    }
  };

  return (
    <Box
      style={theme => ({
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing.xl,
      })}
    >
      <Title
        order={2}
        style={theme => ({
          color: theme.colors.primary[4],
        })}
      >
        {"Ajouter un produit à votre commande"}
      </Title>
      <Space h="xl" />
      <Title
        order={3}
        style={{
          color: "red",
        }}
      >
        {
          "Assurez-vous que les scans pris soient bien suffisants pour les produits ajoutés."
        }
      </Title>
      <Title
        order={5}
        style={{
          fontWeight: "normal",
        }}
      >
        {
          "Jasper ne sera pas responsable des délais dus à des empreintes incorrectes ou manquantes."
        }
      </Title>
      <Space h="xl" />
      <Grid
        gutter="xl"
        style={{flexGrow: 1}}
      >
        <Grid.Col span={6}>
          <Box className={classes.inputContainer}>
            <Title order={5}>{"1. Type de produit"}</Title>
            <Space h="xl" />
            <ButtonsSelect
              data={AddProdctToOrderFormItemTypeChoices}
              value={productGroupType}
              onChange={value => setProductGroupType(value)}
            />
          </Box>
        </Grid.Col>
        <Grid.Col
          span={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box className={classes.inputContainer}>
            <Title order={5}>{"2. Sélectionnez les dents à traiter"}</Title>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Space h="xl" />
              {productGroupType !== null && (
                <DentalArchesSvg
                  teeth={teeth}
                  width={300}
                  height={400}
                  onClick={
                    isRemovableItemType(productGroupType)
                      ? (tooth: number) => {
                          if (isUpperArchTeeth(tooth)) {
                            teeth.includes(tooth)
                              ? setTeeth([
                                  ...teeth.filter(
                                    t => !UpperArchTeeth.includes(t),
                                  ),
                                ])
                              : setTeeth([...teeth, ...UpperArchTeeth]);
                          } else {
                            teeth.includes(tooth)
                              ? setTeeth(
                                  teeth.filter(
                                    t => !LowerArchTeeth.includes(t),
                                  ),
                                )
                              : setTeeth([...teeth, ...LowerArchTeeth]);
                          }
                        }
                      : (tooth: number) =>
                          teeth.includes(tooth)
                            ? setTeeth([...teeth].filter(t => t !== tooth))
                            : setTeeth([...teeth, tooth])
                  }
                />
              )}
            </Box>
          </Box>
        </Grid.Col>
      </Grid>
      {isFormError && (
        <Text
          size="sm"
          style={{color: "red"}}
        >
          Veuillez choisir un type de produit et les dents concernées
        </Text>
      )}
      <Space h="xl" />
      <Button onClick={() => onSubmit()}>Ajouter produit</Button>
      <Space h="xl" />
    </Box>
  );
};

const useStyles = createStyles(theme => ({
  inputContainer: {
    borderRadius: "10px",
    outline: `0.5px solid ${theme.colors.greyScale[9]}`,
    padding: theme.spacing.md,
    height: "100%",
    minHeight: "100%",
    minWidth: "100%",
    backgroundColor: theme.colors.greyScale[9],
  },
}));
