import {
  Box,
  Select,
  Space,
  Switch,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, {FC, useEffect, useState} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {StepsButtons} from "/@/components/StepsButtons";
import {
  getImplantItemPlatformChoices,
  getImplantReferenceBrands,
  getImplantReferenceChoices,
} from "../../../../shared/utils/products/products.utils";
import {
  GetImplantItemChoicesQuery,
  UpdateImplantItemDocument,
  ItemType,
} from "/@/generated/graphql";
import {useMutation} from "@apollo/client";
import {CreateImplantItemStep} from "/@/shared/types/orders/createOrder.types";
import {ProductFromGetOrderByUniqueAttributeQuery} from "/@/shared/types/products/products.types";
import {useCreateOrderStore} from "/@/shared/stores/orders/create-store.store";

type ImplantItemFormData = {
  implantReferenceBrand?: string;
  implantReferenceId?: string;
  connection?: string;
  platform?: string;
  implantBrandSuggestion?: string;
  implantReferenceSuggestion?: string;
  implantPlatformSuggestion?: string;
  implantScanBodySuggestion?: string;
};

type CreateOrderImplantItemImplantReferenceDetailsProps = {
  product: NonNullable<ProductFromGetOrderByUniqueAttributeQuery>;
  implantItemChoicesData: GetImplantItemChoicesQuery;
};
export const CreateOrderImplantItemImplantReferenceDetails: FC<
  CreateOrderImplantItemImplantReferenceDetailsProps
> = ({product, implantItemChoicesData}) => {
  const [updateCreateProductStep] = useCreateOrderStore(state => [
    state.updateCreateProductStep,
  ]);

  const newImplantItem = product.implantItem.find(
    item => item.itemType === ItemType.Implant,
  );

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<ImplantItemFormData>({
    defaultValues: {
      implantReferenceBrand: newImplantItem?.implantReference?.brand ?? undefined,
      implantReferenceId: newImplantItem?.implantReference?.id ?? undefined,
      platform: newImplantItem?.implantPlatform ?? undefined,

      implantBrandSuggestion: newImplantItem?.implantReferenceSuggestion?.split("-").length > 0 ? newImplantItem?.implantReferenceSuggestion.split("-")[0] : "",
      implantReferenceSuggestion: newImplantItem?.implantReferenceSuggestion?.split("-").length > 1 ? newImplantItem?.implantReferenceSuggestion.split("-")[1] : "",
      implantPlatformSuggestion: newImplantItem?.implantReferenceSuggestion?.split("-").length > 3 ? newImplantItem?.implantReferenceSuggestion.split("-")[3] : "",

      implantScanBodySuggestion: newImplantItem.scanBodySuggestion ?? "",
    },
  });
  const watchImplantReferenceBrand = watch("implantReferenceBrand");
  const watchImplantReferenceId = watch("implantReferenceId");
  const [isImplantReferenceNotFound, setIsImplantReferenceNotFound] =
    useState(newImplantItem?.implantReferenceSuggestion ? true : false);
  const [updateImplantItem] = useMutation(UpdateImplantItemDocument, {
    refetchQueries: ["getOrderByUniqueAttribute"],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (watchImplantReferenceBrand && !newImplantItem?.implantReference?.id) {
      setValue("implantReferenceId", "");
    }
  }, [watchImplantReferenceBrand]);

  if (!product.implantItem || product.implantItem.length <= 0) {
    return (
      <div>
        <Text>
          {"Erreur sur l'ajout de produit. Veuillez contacter le support."}
        </Text>
      </div>
    );
  }

  const onSubmit: SubmitHandler<ImplantItemFormData> = data => {
    if (!newImplantItem) {
      console.error("Cannot found newImplantItem");
      return;
    }
    updateImplantItem({
      variables: {
        where: {
          id: newImplantItem?.id,
        },
        data: {
          implantReference: (data.implantReferenceId && !isImplantReferenceNotFound)
            ? {
                connect: {
                  id: data.implantReferenceId,
                },
              }
            : undefined,
          implantPlatform: (data.platform && !isImplantReferenceNotFound) ? {set: data.platform} : undefined,
          implantReferenceSuggestion:
            data.implantReferenceSuggestion &&
            data.implantReferenceSuggestion !== "" &&
            isImplantReferenceNotFound
              ? {
                  set: `${data.implantBrandSuggestion ?? ""} - ${
                    data.implantReferenceSuggestion
                  } - ${
                    data.implantPlatformSuggestion
                  }`,
                }
              : undefined,
          scanBodySuggestion:
            data.implantScanBodySuggestion &&
            data.implantScanBodySuggestion !== ""
              ? {set: data.implantScanBodySuggestion}
              : undefined,
        },
      },
    })
      .then(() => {
        updateCreateProductStep(CreateImplantItemStep.Abutment);
      })
      .catch(err => {
        console.error(err);
      });
  };

  return (
    <Box>
      <Title order={2}>{"Système d'implant et Scan Body"}</Title>
      <Text size="md">
        {
          "Les informations précises nous permettent de vous assurer de meilleurs délais de livraison"
        }
      </Text>
      <Space h="md" />
      <Title
        order={4}
        style={theme=>({color: theme.colors.primary[5]})}
      >
        Implant
      </Title>
      <Space h="sm" />
      <form onSubmit={handleSubmit(onSubmit)}>
        {isImplantReferenceNotFound === false && (
          <>
            <Text
              size="sm"
              style={{fontWeight: "bold"}}
            >
              {"Marque d'implant"}
            </Text>
            <Space h="sm" />
            <Controller
              name="implantReferenceBrand"
              rules={{required: true}}
              control={control}
              render={({field}) => (
                <Select
                  {...field}
                  data={getImplantReferenceBrands(implantItemChoicesData)}
                  placeholder="Choisissez une marque d'implant"
                  data-testid="select-implant-brand"
                />
              )}
            />
            {errors?.implantReferenceBrand?.type === "required" && (
              <Text
                size="sm"
                style={{color: "red"}}
              >
                {"Veuillez choisir une marque d'implant"}
              </Text>
            )}
            <Space h="md" />
            {watchImplantReferenceBrand && (
              <>
                <Text
                  size="sm"
                  style={{fontWeight: "bold"}}
                >
                  {"Référence"}
                </Text>
                <Controller
                  name="implantReferenceId"
                  rules={{required: true}}
                  control={control}
                  render={({field}) => (
                    <Select
                      {...field}
                      data={getImplantReferenceChoices(
                        implantItemChoicesData,
                      ).filter(
                        implantReference =>
                          implantReference.brand === watchImplantReferenceBrand,
                      )}
                      placeholder="Choisissez un système d'implant"
                      data-testid="select-implant-reference"
                    />
                  )}
                />
                {errors?.implantReferenceId?.type === "required" && (
                  <Text
                    size="sm"
                    style={{color: "red"}}
                  >
                    {"Veuillez choisir un système d'implant"}
                  </Text>
                )}
                <Space h="xl" />
                <Text
                  size="sm"
                  style={{fontWeight: "bold"}}
                >
                  {"Plateforme implantaire"}
                </Text>
                <Controller
                  name="platform"
                  rules={{required: true}}
                  control={control}
                  render={({field}) => (
                    <Select
                      {...field}
                      data={getImplantItemPlatformChoices(
                        implantItemChoicesData.getAllImplantReferences.find(
                          implantReference =>
                            implantReference.id === watchImplantReferenceId,
                        )?.platforms ?? [],
                      )}
                      placeholder="Choisissez une plateforme implantaire"
                      data-testid="select-implant-plateform"
                    />
                  )}
                />
                {errors?.platform?.type === "required" && (
                  <Text
                    size="sm"
                    style={{color: "red"}}
                  >
                    Veuillez choisir une plateforme
                  </Text>
                )}
                <Space h="xl" />
              </>
            )}
          </>
        )}
        {isImplantReferenceNotFound && (
          <>
            <Space h="sm" />
            <Text size="sm">Fabriquant</Text>
            <Controller
              name="implantBrandSuggestion"
              rules={{required: true}}
              control={control}
              render={({field}) => (
                <TextInput
                  value={field.value}
                  placeholder="Fabriquant (ex: Zimmer, Straumann, Biotech Dental, etc.)"
                  onChange={value => field.onChange(value)}
                />
              )}
            />
            {errors?.implantBrandSuggestion?.type === "required" && (
              <Text
                size="sm"
                style={{color: "red"}}
              >
                Veuillez entrer la marque de votre implant
              </Text>
            )}
            <Space h="sm" />
            <Text size="sm">{"Système"}</Text>
            <Controller
              name="implantReferenceSuggestion"
              rules={{required: true}}
              control={control}
              render={({field}) => (
                <TextInput
                  value={field.value}
                  placeholder="Système (ex: NobelActive pour NobelBiocare, Kontact Standard pour Biotech Dental, etc.)"
                  onChange={value => field.onChange(value)}
                />
              )}
            />
            {errors?.implantReferenceSuggestion?.type === "required" && (
              <Text
                size="sm"
                style={{color: "red"}}
              >
                Veuillez entrer la référence de votre implant
              </Text>
            )}
            <Space h="sm" />
            <Text size="sm">Plateforme implantaire / diamètre</Text>
            <Controller
              name="implantPlatformSuggestion"
              rules={{required: true}}
              control={control}
              render={({field}) => (
                <TextInput
                  value={field.value}
                  placeholder="ex: Narrow Platform, Regular Platform pour Nobel Biocare; 3.0, 3.6, 4.9 pour Biotech Dental, etc."
                  onChange={value => field.onChange(value)}
                />
              )}
            />
            {errors?.implantReferenceSuggestion?.type === "required" && (
              <Text
                size="sm"
                style={{color: "red"}}
              >
                Veuillez entrer les détails de votre implant
              </Text>
            )}
            <Space h="xl" />
          </>
        )}
        <Switch
          checked={isImplantReferenceNotFound}
          onChange={event =>
            setIsImplantReferenceNotFound(event.currentTarget.checked)
          }
          label="Je ne trouve pas mon système d'implant"
        />
        <Space h="xl" />
        <Title
          order={4}
          style={theme=>({color: theme.colors.primary[5]})}
        >
          {"Scan Body"}
        </Title>
        <Space h="sm" />
        <Text size="sm">{"Référence de votre Scan Body"}</Text>
        <Controller
          name="implantScanBodySuggestion"
          rules={{required: true}}
          control={control}
          render={({field}) => (
            <TextInput
              value={field.value}
              placeholder="Par exemple: scanbody Biotech Dental Kontact (KPSCANP), scanbody DESS compatible Biotech Dental Kontact"
              onChange={value => field.onChange(value)}
              data-testid="input-implant-scan-body"
            />
          )}
        />
        <Text
          style={{fontStyle: "italic"}}
          size="xs"
        >
          {
            'Le scan body nous permet de trouver les librairies de conception 3D de votre fabricant d\'implant. Si vous ne connaissez pas la référence exacte, indiquez les informations que vous avez ou bien "NA".'
          }
        </Text>
        {errors?.implantScanBodySuggestion?.type === "required" && (
          <Text
            size="sm"
            style={{color: "red"}}
          >
            Veuillez renseigner les détails de votre Scan Body
          </Text>
        )}
        <Space h="xl" />
        <StepsButtons
          handleSubmit={() => handleSubmit(onSubmit)()}
          setPreviousAction={updateCreateProductStep}
          isFirst={true}
        />
      </form>
    </Box>
  );
};
