import React, {FC} from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {getTeethLabel} from "@jasper/shared";
import {Box, Text} from "@mantine/core";
import {ProductGenericType} from "/@/shared/types/products/products.types";
import {GetOrderByUniqueAttributeQuery, ItemType} from "/@/generated/graphql";

import {
  isAnatomyItemType,
  isImplantItemType,
  isRemovableItemType,
  getItemMultiShadeLabel,
  getItemMaterialLabel,
  getItemInlayCoreHasClavetteLabel,
  getItemShadeLabel,
  getImplantRetentionTypeLabel,
  getItemAbutmentMaterialLabel,
  sortItems,
  getItemAbutmentTypeLabel,
  getItemTypeWithCountLabel,
  getItemInlayCoreMaterialLabel,
  getItemInlayCoreScanPostLabel,
} from "@jasper/shared";

type ItemsDescriptionProps = {
  product: ProductGenericType;
  order?: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"];
};

export const ItemsDescription: FC<ItemsDescriptionProps> = ({product}) => {
  if (!product) return null;
  let items = isAnatomyItemType(product.productType)
    ? product.anatomyItem
    : isImplantItemType(product.productType)
    ? product.implantItem
    : isRemovableItemType(product.productType)
    ? product.removableItem
    : null;
  if (!items || (Array.isArray(items) && items.length < 1)) return null;
  if (!Array.isArray(items)) {
    items = [items];
  }

  const showItemOrProductTeeths = (productType: ItemType, itemType: ItemType): boolean => {
    if (isAnatomyItemType(productType)){
      return (true);
    }
    if (isImplantItemType(productType) && itemType !== ItemType.BridgeImplant){
      return (true);
    }
    return (false);
  };

  return (
    <Box>
      {sortItems(items, product.productType).map((item: any) => {
        if (item.itemType === ItemType.BridgePontic) {
          return null;
        }
        return (
          <Box
            key={item.id}
            style={{textAlign: "start"}}
          >
            <Text size="sm">
              {`${getItemTypeWithCountLabel(item)} ${getTeethLabel(
                item.itemType,
                showItemOrProductTeeths(product.productType, item.itemType) ? item.teeth : product.teeth,
                item.teeth,
              )}`}
            </Text>
            {item.itemType !== ItemType.InlayCore && (
              <div style={{marginLeft: "1rem"}}>
                {item?.itemMaterial && (
                  <Text size="sm">{`- ${getItemMaterialLabel(item)}`}</Text>
                )}
                {item?.itemShade && (
                  <Text size="sm">{`- ${getItemShadeLabel(item)}`}</Text>
                )}
                {item?.teethshadeType === "MULTI_SHADE" && (
                  <>
                    <Text size="sm">- Teinte:</Text>
                    <Text size="sm" style={{ whiteSpace: "pre-line", marginLeft: "1rem" }}>{`${getItemMultiShadeLabel(item)}`}</Text>
                  </>
                )}
                {item?.teethShade && (
                  <Text size="sm">{`- ${getItemShadeLabel(item)}`}</Text>
                )}
              </div>
            )}
            <div style={{marginLeft: "1rem"}}>
              {getItemInlayCoreMaterialLabel(item) && (
                <Text size="sm">{`- ${getItemInlayCoreMaterialLabel(
                  item,
                )}`}</Text>
              )}
              {getItemInlayCoreHasClavetteLabel(item) && (
                <Text size="sm">{`- ${getItemInlayCoreHasClavetteLabel(
                  item,
                )}`}</Text>
              )}
              {getItemInlayCoreScanPostLabel(item) && (
                <Text size="sm">{`- ${getItemInlayCoreScanPostLabel(
                  item,
                )}`}</Text>
              )}
              {getImplantRetentionTypeLabel(item) && (
                <Text size="sm">{`- ${getImplantRetentionTypeLabel(
                  item,
                )}`}</Text>
              )}
              {getItemAbutmentTypeLabel(item) && (
                <Text size="sm">{`- ${getItemAbutmentTypeLabel(item)}`}</Text>
              )}
              {getItemAbutmentMaterialLabel(item) && (
                <Text size="sm">{`- ${getItemAbutmentMaterialLabel(
                  item,
                )}`}</Text>
              )}
            </div>
          </Box>
        );
      })}
    </Box>
  );
  return null;
};
