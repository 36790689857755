import React, {FC} from "react";
import {
  GetImplantItemChoicesQuery,
  GetOrderByUniqueAttributeQuery,
  GetUserProductPreferencesQuery,
  ItemType,
} from "/@/generated/graphql";
import {CreateOrderImplantItemPreferences} from "./CreateOrderImplantItemPreferences";
import {Grid, Space, Stepper} from "@mantine/core";
import {createStyles} from "@mantine/emotion";
import {CreateProductSummarySideBar} from "../CreateProductSummarySideBar";
import {CreateOrderImplantItemAbutmentDetails} from "/@/pages/products/create/implant-item/CreateOrderImplantItemAbutmentDetails";
import {CreateOrderImplantItemImplantReferenceDetails} from "/@/pages/products/create/implant-item/CreateOrderImplantItemImplantReferenceDetails";
import {CreateOrderImplantItemItemDetails} from "./CreateOrderImplantItemItemDetails";
import {CreateImplantItemStep} from "/@/shared/types/orders/createOrder.types";
import {useCreateOrderStore} from "/@/shared/stores/orders/create-store.store";

type CreateOrderImplantItemsProps = {
  product: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
  >[0];
  patient: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["patient"];
  userProductPreferences: GetUserProductPreferencesQuery;
  implantItemChoicesData: GetImplantItemChoicesQuery;
};

export const CreateOrderImplantItems: FC<CreateOrderImplantItemsProps> = ({
  product,
  patient,
  userProductPreferences,
  implantItemChoicesData,
  order,
}) => {
  const createProductStep = useCreateOrderStore(
    state => state.createProductStep,
  );
  const {classes} = useStyles();
  const dataContent = () => {
    switch (createProductStep) {
      case CreateImplantItemStep.ImplantReference:
        return (
          <CreateOrderImplantItemImplantReferenceDetails
            product={product}
            implantItemChoicesData={implantItemChoicesData}
          />
        );
      case CreateImplantItemStep.Abutment:
        return (
          <CreateOrderImplantItemAbutmentDetails
            product={product}
            patient={patient}
            implantItemChoicesData={implantItemChoicesData}
          />
        );
      case CreateImplantItemStep.Item:
        return (
          <CreateOrderImplantItemItemDetails
            product={product}
            implantItemChoicesData={implantItemChoicesData}
            order={order}
          />
        );
      case CreateImplantItemStep.Preferences:
        return (
          <CreateOrderImplantItemPreferences
            product={product}
            userProductPreferences={userProductPreferences}
          />
        );
      default:
        return <div>ERROR create implant item</div>;
    }
  };

  return (
    <Grid
      columns={36}
      gutter={0}
      style={{height: "100%"}}
    >
      <Grid.Col
        span={createProductStep < CreateImplantItemStep.Review ? 26 : 36}
        style={theme => ({padding: theme.spacing.lg})}
      >
        <Stepper
          active={createProductStep}
          classNames={{step: classes.stepper, stepBody: classes.stepBody}}
        >
          <Stepper.Step label="Implant" />
          <Stepper.Step label="Pilier" />
          <Stepper.Step
            label={
              product.productType === ItemType.BridgeImplant
                ? "Bridge"
                : "Couronne"
            }
          />
          <Stepper.Step label="Préférences" />
        </Stepper>
        <Space h="xl" />
        {dataContent()}
      </Grid.Col>
      {createProductStep !== CreateImplantItemStep.Review && (
        <Grid.Col
          span={10}
          style={theme => ({
            padding: theme.spacing.sm,
            backgroundColor: theme.colors.primaryLight[9],
          })}
        >
          <CreateProductSummarySideBar
            product={product}
            patient={patient}
          />
        </Grid.Col>
      )}
    </Grid>
  );
};

const useStyles = createStyles(theme => ({
  stepper: {
    flexDirection: "column",
  },
  stepBody: {
    marginLeft: 0,
    marginTop: theme.spacing.xs,
    alignItems: "center",
  },
}));
