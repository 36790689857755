// import {ItemMaterial} from "/@/generated/graphql";
import React from "react";
import {Box, Text, Title} from "@mantine/core";
import {
  AbutmentType,
  GetOrderByUniqueAttributeQuery,
  Product,
  ItemType,
  RetentionType,
  WorkflowType,
} from "/@/generated/graphql";

// export const AnatomyItemShades = Object.values(Shade);
// export const ImplantItemShades = Object.values(Shade);

export type ProductFromGetOrderByUniqueAttributeQuery = NonNullable<
  GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
>[number];

export type ProductGenericType =
  | Product
  | ProductFromGetOrderByUniqueAttributeQuery;

export const LayType = [
  {value: ItemType.Inlay, label: "Inlay"},
  {value: ItemType.Onlay, label: "Onlay"},
  {value: ItemType.Overlay, label: "Overlay"},
];

export const SplintTypeChoices = [
  {value: ItemType.BleachingTray, label: "Blanchiment"},
  {value: ItemType.NightGuard, label: "Anti-bruxisme"},
  {value: ItemType.Retainer, label: "Contention"},
];

export const SplintHardnessChoices = [
  {value: "NIGHT_GUARD_SOFT_MATERIAL", label: "Souple"},
  {value: "NIGHT_GUARD_HARD_MATERIAL", label: "Dure"},
  {value: "NIGHT_GUARD_MEDIUM_MATERIAL", label: "Semi-rigide"},
];

export const AbutmentTypeChoices = [
  {value: AbutmentType.Standard, label: "Standard"},
  {value: AbutmentType.Custom, label: "Personnalisé"},
];

export const StandardAbutmentTypeChoices = [
  {value: AbutmentType.Standard, label: "Pilier original - fabriquant"},
  {value: AbutmentType.StandardGeneric, label: "Pilier standard générique"},
];

export const RetentionTypeChoices = [
  {value: RetentionType.CementRetained, label: "Scellé"},
  {value: RetentionType.ScrewRetained, label: "Transvissé"},
];

// export const AbutmentMaterialChoices = [
//   {
//     value: AbutmentMaterial.CustomTitanium,
//     label: "Pilier en titane",
//   },
//   {
//     value: AbutmentMaterial.CustomZirconiaTitaniumHybrid,
//     label: "Pilier en zircone-titane",
//   },
//   {value: AbutmentMaterial.TiBase, label: "Pilier Ti-Base"},
// ];

export const WorkflowTypeChoices = [
  {
    value: WorkflowType.OneAppointmentDirect,
    label: (
      <Box
        style={theme => ({
          display: "flex",
          flexDirection: "column",
          margin: theme.spacing.sm,
        })}
      >
        <Title order={6}>Finition Directe: 1 RDV</Title>
        <Text size="sm">
          {
            "A privilégier en cas d'occlusion nette et de moins de 5 dents manquantes."
          }
        </Text>
      </Box>
    ),
  },
  {
    value: WorkflowType.WaxRim,
    label: (
      <Box
        style={theme => ({
          display: "flex",
          flexDirection: "column",
          margin: theme.spacing.sm,
        })}
      >
        <Title order={6}>{"Cire d'occlusion: 2 RDVs"}</Title>
        <Text size="sm">
          {
            "A privilégier lorsqu'il reste quelques dents au patient. Permet d'établir la relation maxillo mandibulaire."
          }
        </Text>
      </Box>
    ),
  },
  {
    value: WorkflowType.WithFitting,
    label: (
      <Box
        style={theme => ({
          display: "flex",
          flexDirection: "column",
          margin: theme.spacing.sm,
        })}
      >
        <Title order={6}>
          {"Essayage avec mise en place des dents: 2 RDVs"}
        </Title>
        <Text size="sm">
          {
            "A privilégier s'il manque 3 dents antérieures ou plus. Aide à visualiser l'esthetique et l'occlusion."
          }
        </Text>
      </Box>
    ),
  },
];

export const FullDentureTypeChoices = [
  {
    value: ItemType.FullDenture,
    label: "PAC Standard",
  },
  {
    value: ItemType.ImmediateDenture,
    label: "PAC Immédiate",
  },
];

export const NewFullDentureStepChoices = [
  {
    value: 1,
    label: "Étape 1: Commande de la cire d'occlusion",
  },
  {
    value: 2,
    label: "Étape 2: Commande de la PAC définitive",
  },
];

export const yesNoChoices = [
  {label: "Oui", value: "YES"},
  {label: "Non", value: "NO"},
];

export const fullDentureTeethShapeChoices = [
  {label: "Carrées", value: "SQUARE"},
  {label: "Arrondies", value: "ROUNDED"},
  {label: "Triangulaires", value: "TRIANGULAR"},
];

export const UpperArchTeeth = [
  11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28,
];

export const LowerArchTeeth = [
  31, 32, 33, 34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48,
];

export const bridgeMaterials = [];
