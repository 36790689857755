import {Box, Space, Text, TextInput, Title} from "@mantine/core";
import React, {FC} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {ButtonsSelect} from "/@/components/ButtonsSelect";
import {StepsButtons} from "/@/components/StepsButtons";
import {
  AbutmentType,
  GetImplantItemChoicesQuery,
  GetOrderByUniqueAttributeDocument,
  GetOrderByUniqueAttributeQuery,
  RetentionType,
  UpdateImplantItemDocument,
  ItemType,
} from "/@/generated/graphql";
import {
  AbutmentTypeChoices,
  StandardAbutmentTypeChoices,
  ProductFromGetOrderByUniqueAttributeQuery,
} from "../../../../shared/types/products/products.types";
import {useMutation} from "@apollo/client";
import {CreateImplantItemStep} from "/@/shared/types/orders/createOrder.types";
import {} from "/@/shared/utils/products/products.utils";
import client from "/@/apolloClient";
import {useCreateOrderStore} from "/@/shared/stores/orders/create-store.store";

import {
  formatChoicesData,
} from "@jasper/shared";

type ImplantItemFormData = {
  abutmentType?: AbutmentType;
  retentionType?: RetentionType;
  abutmentMaterial?: string;
  standardAbutmentSuggestion?: string;
  standardAbutmentType: AbutmentType;
};

type CreateOrderImplantItemAbutmentDetailsProps = {
  product: ProductFromGetOrderByUniqueAttributeQuery;
  patient: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["patient"];
  implantItemChoicesData: GetImplantItemChoicesQuery;
};
export const CreateOrderImplantItemAbutmentDetails: FC<
  CreateOrderImplantItemAbutmentDetailsProps
> = ({product, implantItemChoicesData}) => {
  const [updateCreateProductStep] = useCreateOrderStore(state => [
    state.updateCreateProductStep,
  ]);

  const newImplantItem = product.implantItem.find(
    item => item.itemType === ItemType.Implant,
  );

  const {
    control,
    watch,
    handleSubmit,
    formState: {errors},
  } = useForm<ImplantItemFormData>({
    defaultValues: {
      abutmentType: (newImplantItem?.abutmentType === AbutmentType.StandardGeneric ? AbutmentType.Standard : newImplantItem.abutmentType) ?? undefined,
      standardAbutmentType: newImplantItem?.abutmentType ?? undefined,
      standardAbutmentSuggestion: newImplantItem?.standardAbutmentSuggestion ?? "",
      abutmentMaterial: newImplantItem?.abutmentMaterial?.id ?? undefined,
    },
  });
  const watchAbutmentType = watch("abutmentType");
  const watchRetentionType = watch("retentionType");

  const materialFormChoices = implantItemChoicesData
    ? formatChoicesData(
        implantItemChoicesData.getItemMaterialsWhere.filter(m => m.abutment),
      )
    : [];

  const [updateImplantItem] = useMutation(UpdateImplantItemDocument, {
    refetchQueries: ["getOrderByUniqueAttribute"],
    awaitRefetchQueries: true,
  });

  if (
    !product.implantItem ||
    product.implantItem.length <= 0 ||
    !materialFormChoices
  ) {
    return (
      <div>
        <Text>
          {"Erreur sur l'ajout de produit. Veuillez contacter le support."}
        </Text>
      </div>
    );
  }

  const onSubmit: SubmitHandler<ImplantItemFormData> = data => {
    if (!newImplantItem) {
      console.error("Cannot found newImplantItem");
      return;
    }
    updateImplantItem({
      variables: {
        where: {
          id: newImplantItem?.id,
        },
        data: {
          abutmentType: (data.standardAbutmentType && data.abutmentType === AbutmentType.Standard)
            ? {set: data.standardAbutmentType}
            : data.abutmentType
            ? {set: data.abutmentType}
            : undefined,
          abutmentMaterial: data.abutmentMaterial
            ? {connect: {id: data.abutmentMaterial}}
            : undefined,
          standardAbutmentSuggestion:
            data.standardAbutmentSuggestion &&
            data.standardAbutmentSuggestion !== ""
              ? {
                  set: data.standardAbutmentSuggestion,
                }
              : undefined,
        },
      },
    })
      .then(() => {
        updateCreateProductStep(CreateImplantItemStep.Item);
      })
      .then(async () => {
        await client.refetchQueries({
          include: [GetOrderByUniqueAttributeDocument],
        });
      })
      .catch(() => {
        //console.log("error");
      });
  };

  return (
    <Box>
      <Title order={2}>{"Pilier implantaire"}</Title>
      <Text
        style={{fontStyle: "italic"}}
        size="sm"
      >
        {
          "Un pilier personnalisé est usiné sur mesure et adapté au mieux au cas de votre patient, grâce aux librairies de CFAO de votre fabricant d'implant. Le délai de fabrication d'un pilier personnalisé est plus court que celui d'un pilier standard."
        }
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Space h="md" />
        <Text
          size="sm"
          style={{fontWeight: "bold"}}
        >
          {"Type de pilier"}
        </Text>
        <Controller
          name="abutmentType"
          rules={{required: true}}
          control={control}
          render={({field}) => (
            <ButtonsSelect
              data={AbutmentTypeChoices}
              value={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.abutmentType?.type === "required" && (
          <Text
            size="sm"
            style={{color: "red"}}
          >
            Veuillez choisir un type de pilier
          </Text>
        )}
        <Space h="md" />
        {(watchAbutmentType === AbutmentType.Standard ||
          watchAbutmentType === AbutmentType.StandardGeneric) && (
          <>
            <Text size="sm">{"Type de pilier standard "}</Text>
            <Controller
              name="standardAbutmentType"
              rules={{
                required:
                  watchAbutmentType === AbutmentType.Standard ||
                  watchAbutmentType === AbutmentType.StandardGeneric
                    ? true
                    : false,
              }}
              control={control}
              render={({field}) => (
                <ButtonsSelect
                  data={StandardAbutmentTypeChoices}
                  value={field.value}
                  onChange={value => field.onChange(value)}
                />
              )}
            />
            {errors?.standardAbutmentType?.type === "required" && (
              <Text
                size="sm"
                style={{color: "red"}}
              >
                Veuillez choisir un type de pilier standard
              </Text>
            )}
            <Space h="md" />
            <Text size="sm">{"Préférence de pilier standard "}</Text>
            <Controller
              name="standardAbutmentSuggestion"
              rules={{required: false}}
              control={control}
              render={({field}) => (
                <TextInput
                  value={field.value}
                  placeholder="Si vous avez des préférences particulières de pilier standard (Hauteur de pilier, angulé, etc.)"
                  onChange={value => field.onChange(value)}
                  data-testid="input-implant-suggestion-abutment"
                />
              )}
            />
            {errors?.standardAbutmentSuggestion?.type === "required" && (
              <Text
                size="sm"
                style={{color: "red"}}
              >
                Veuillez entrer la marque de votre implant
              </Text>
            )}
            <Space h="md" />
          </>
        )}

        <Space h="xl" />
        <StepsButtons
          handleSubmit={() => handleSubmit(onSubmit)()}
          setPreviousAction={updateCreateProductStep}
          previousStep={CreateImplantItemStep.ImplantReference}
        />
      </form>
    </Box>
  );
};
