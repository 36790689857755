import React, {FC} from "react";
import {
  GetOrderByUniqueAttributeQuery,
  SendOrderHelpRequestDocument,
} from "/@/generated/graphql";
import {useMutation} from "@apollo/client";
import {Box, Button, Flex, Space, Text, Textarea, Title} from "@mantine/core";
import {IconX} from "@tabler/icons-react";
import {notifications} from "@mantine/notifications";

type OrderGetHelpProps = {
  order: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]
  >;
  closeModal?: () => void;
};

export const OrderGetHelp: FC<OrderGetHelpProps> = ({
  order,
  closeModal = () => null,
}) => {
  const [helpRequestMessage, setHelpRequestMessage] = React.useState("");
  const [sendOrderHelpRequest, {error}] = useMutation(
    SendOrderHelpRequestDocument,
  );

  const onSubmit = () => {
    sendOrderHelpRequest({
      variables: {
        orderId: order.id,
        message: helpRequestMessage,
      },
    }).then(() => {
      notifications.show({
        color: "green",
        title: "Votre demande a bien été envoyée",
        message: "",
      });
      closeModal();
    });
  };

  return (
    <Box>
      <Flex
        w="100%"
        justify="right"
      >
        <IconX
          onClick={() => closeModal()}
          style={{cursor: "pointer"}}
          color="black"
        />
      </Flex>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Title
          order={2}
          style={theme=>({color: theme.colors.primary[5]})}
        >
          Demande d'aide pour la commande
        </Title>
        <Space h="md" />
        <Title
          order={2}
          style={theme=>({color: theme.colors.primary[5]})}
        >{`${order.orderReference}`}</Title>
        <Space h="md" />
        <Title order={4}>
          Votre conseiller Jasper reviendra vers vous très rapidement.
        </Title>
        <Space h="lg" />
        <Textarea
          color="primary"
          placeholder="Veuillez indiquer la nature de votre demande d'aide"
          onChange={event => setHelpRequestMessage(event.target.value)}
          w="80%"
          autosize
          minRows={4}
          maxRows={12}
        />
        <Space h="md" />
        <Button onClick={onSubmit}>Envoyer</Button>

        {error && (
          <>
            <Space h="sm" />
            <Text
              size="sm"
              style={theme=>({color: theme.colors.red[5]})}
              >
              Une erreur est survenue, veuillez contacter votre conseiller
              Jasper directement
            </Text>
          </>
        )}
      </Box>
      <Space h="md" />
    </Box>
  );
};
