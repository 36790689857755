import {graphql} from "/@/generated";

export const getUserProductPreferences = graphql(`
  query GetUserProductPreferences($where: ProductPreferencesWhereInput) {
    getUserProductPreferences(where: $where) {
      id
      shadeType
      productPreferenceType
      fixedProsthesisPreferences {
        itemType
        occlusalContactTightness
        proximalContactTightness
        noOcclusalClearance
        occlusalStaining
        interproximalContactDesign
        anteriorPosteriorPonticDesign
      }
      removableProsthesisPreferences {
        itemType
        splintHardness
        teethShape
      }
    }
  }

  query GetAccountProductPreferences {
    getAccountProductPreferences {
      id
      shadeType
      productPreferenceType
      dentist {
        id
        firstName
        lastName
      }
      fixedProsthesisPreferences {
        id
        itemType
        occlusalContactTightness
        proximalContactTightness
        noOcclusalClearance
        occlusalStaining
        interproximalContactDesign
        anteriorPosteriorPonticDesign
      }
      removableProsthesisPreferences {
        id
        itemType
        splintHardness
        teethShape
      }
    }
  }
`);

export const updateProductPreferences = graphql(`
  mutation UpdateProductPreferences(
    $where: ProductPreferencesWhereUniqueInput!
    $data: ProductPreferencesUpdateInput!
  ) {
    updateProductPreferences(where: $where, data: $data) {
      id
    }
  }
`);

export const createProductPreferences = graphql(`
  mutation CreateProductPreferences($args: ProductPreferencesCreateInput!) {
    createProductPreferences(args: $args) {
      id
    }
  }
`);
