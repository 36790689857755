/// <reference types="vite-plugin-svgr/client" />

import {
  Box,
  Group,
  AppShell,
  Text,
  UnstyledButton,
  AppShellSection,
} from "@mantine/core";
import React from "react";
import {
  IconHelp,
  IconHome,
  IconLogout,
  IconMessageCircle,
  IconNotes,
  IconUser,
} from "@tabler/icons-react";
import Logo from "/@/assets/images/logo.svg";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "/@/shared/contexts/AuthContext";
import {Crisp} from "crisp-sdk-web";
import packageJson from "../../../package.json";
import { createStyles } from "@mantine/emotion";

type SideBarMenuItem = {
  name: string;
  icon: React.ReactNode;
  linkTo: string;
  color: string;
};

const upperSideBarMenuItems: SideBarMenuItem[] = [
  {
    name: "Accueil",
    icon: <IconHome color="grey" />,
    linkTo: "/",
    color: "primary",
  },
  {
    name: "Commandes",
    icon: <IconNotes color="grey" />,
    linkTo: "/orders",
    color: "primary",
  },
];

const lowerSideBarMenuItems: SideBarMenuItem[] = [
  {
    name: "Centre d'aide",
    icon: <IconHelp color="grey" />,
    linkTo:
      "https://jasperdentals.notion.site/jasperdentals/e61c7e164e42454891f7b67e1337ff23?v=b0bdf98ee19947db9457b1ee50cf56da",
    color: "primary",
  },
  {
    name: "Chat",
    icon: <IconMessageCircle color="grey" />,
    linkTo: "",
    color: "primary",
  },
  {
    name: "Compte",
    icon: <IconUser color="grey" />,
    linkTo: "/settings",
    color: "primary",
  },
  {
    name: "Se Déconnecter",
    icon: <IconLogout color="grey" />,
    linkTo: "/logout",
    color: "primary",
  },
];

export const LayoutDrawer = props => {
  return (
    <AppShell.Navbar
      {...props}
      width={{base: 225}}
      style={theme => ({
        backgroundColor: theme.colors.primaryLight[9],
      })}
      p={"sm"}
    >
      <AppShellSection
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          style={theme => ({
            marginBottom: theme.spacing.md,
            marginTop: theme.spacing.sm,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          })}
        >
          <img
            src={Logo}
            style={{height: "80%"}}
          />
          {import.meta.env.MODE !== "production" && (
            <Text size="sm">
              build {import.meta.env.VITE_APP_VERSION} - {import.meta.env.MODE}
            </Text>
          )}
        </Box>
      </AppShellSection>
      <AppShellSection
        style={theme => ({
          marginTop: theme.spacing.lg,
          backgroundColor: theme.colors.primaryLight[9],
        })}
        px={"md"}
        grow
      >
        <UpperLinks />
      </AppShellSection>
      <AppShellSection px={"md"}>
        <LowerLinks />
      </AppShellSection>
    </AppShell.Navbar>
  );
};


const useStyles = createStyles((theme) => ({
  unstyledButton: {
    display: "block",
    width: "100%",
    padding: theme.spacing.xs,
    borderRadius: theme.radius.sm,
    color:
      theme.primaryColor === "dark" ? theme.colors.dark[0] : theme.black,

    "&:hover": {
      backgroundColor:
        theme.primaryColor === "dark"
          ? theme.colors.dark[6]
          : theme.colors.primaryLight[7],
    },
  },
}));

function MainLink({name, icon, linkTo}: SideBarMenuItem) {
  const {logOut} = useAuthContext();
  const navigate = useNavigate(); 
  const {classes} = useStyles();

  return (
    <UnstyledButton
      className={
        classes.unstyledButton
      }
      onClick={async () => {
        if (linkTo === "/logout") {
          // const removeDesktopRefreshTokenSuccess =
          //   await removeRefreshTokenFromDesktop();
          // if (!removeDesktopRefreshTokenSuccess) {
          //   console.error("Could not remove refresh token from desktop");
          // }
          logOut();
        } else if (name === "Centre d'aide") {
          window.open(linkTo, "_blank");
        } else if (name === "Chat") {
          if (Crisp.chat.isVisible()) {
            Crisp.chat.hide();
          } else {
            if (!Crisp.chat.isChatOpened()) {
              Crisp.chat.open();
            }
            Crisp.chat.show();
          }
        } else {
          navigate(linkTo);
        }
      }}
    >
      <Group>
        {icon}
        <Text
          size="sm"
          style={{
            fontWeight: "500",
          }}
        >
          {name}
        </Text>
      </Group>
    </UnstyledButton>
  );
}

export function UpperLinks() {
  const links = upperSideBarMenuItems.map(link => (
    <MainLink
      {...link}
      key={link.name}
    />
  ));
  return <div>{links}</div>;
}

export function LowerLinks() {
  const links = lowerSideBarMenuItems.map(link => (
    <MainLink
      {...link}
      key={link.name}
    />
  ));
  return (
    <div>
      <div>{links}</div>
      <div style={{color: "lightgrey"}}>
        {
          <Text
            size="xs"
            style={{textAlign: "center"}}
          >
            v{packageJson.version}
          </Text>
        }
      </div>
    </div>
  );
}
