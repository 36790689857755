import { Stepper, useMantineTheme} from "@mantine/core";
import {createStyles} from "@mantine/emotion";
import {
  IconAlertTriangle,
  IconBasket,
  IconCircleCheck,
  IconTools,
  IconTruckDelivery,
} from "@tabler/icons-react";
import React, {FC} from "react";
import {Order, OrderStatus} from "/@/generated/graphql";
import {getOrderStatusStep} from "@jasper/shared";

type OrderStatusStepperProps = {
  orderStatus: number;
  order: Order;
  labelsUnder?: boolean;
  grow?: boolean;
};

export const OrderStatusStepper: FC<OrderStatusStepperProps> = ({
  orderStatus,
  order,
  labelsUnder = false,
  grow = false,
}) => {
  const {classes} = useStyles({orderStatus, grow, labelsUnder});
  const theme = useMantineTheme();
  const orderDate = new Date(
    order.history?.find(
      history => history.status === OrderStatus.Confirmed,
    )?.createdAt,
  );
  let expectedDeliveryDate = null;
  if (order.deliveryDate !== null) {
    expectedDeliveryDate = new Date(0);
    expectedDeliveryDate.setUTCMilliseconds(order.deliveryDate);
  } else {
    expectedDeliveryDate = new Date();
    expectedDeliveryDate.setDate(orderDate.getDate() + 10);
  }

  return (
    <Stepper
      active={orderStatus}
      wrap={false}
      classNames={{
        root: classes.stepper,
        steps: classes.steps,
        stepLabel: classes.stepperText,
        stepDescription: classes.stepperText,
        separator: classes.stepperLine,
        step: labelsUnder ? classes.step : "",
        stepBody: labelsUnder ? classes.stepBody : "",
        stepIcon: classes.stepIcon,
        stepCompletedIcon: classes.stepIconCompleted,
      }}
    >
      <Stepper.Step
        label={orderStatus === 0 ? "Commencée" : "Commandé"}
        icon={
          orderStatus === 0 ? <IconAlertTriangle style={{color: "red"}} /> : <IconBasket />
        }
        color={
          orderStatus === getOrderStatusStep(OrderStatus.Draft)
            ? "red"
            : theme.colors.primary[5]
        }
        completedIcon={<IconBasket />}
        description={`le ${orderDate.toLocaleDateString() ?? order.createdAt.toLocaleDateString()}`}
      ></Stepper.Step>
      <Stepper.Step
        label="Fabrication"
        icon={<IconTools />}
        completedIcon={<IconTools />}
      ></Stepper.Step>
      <Stepper.Step
        label="Expédition"
        completedIcon={<IconTruckDelivery />}
        icon={<IconTruckDelivery />}
      ></Stepper.Step>
      <Stepper.Step
        icon={<IconCircleCheck />}
        completedIcon={<IconCircleCheck />}
        label="Livraison"
        description={
          orderStatus === 0
            ? "Non planifiée"
            : `Prévue le ${expectedDeliveryDate.toLocaleDateString()}`
        }

      ></Stepper.Step>
    </Stepper>
  );
};

const useStyles = createStyles((theme, {grow, labelsUnder}: any) => ({
  container: {
    padding: theme.spacing.sm,
  },
  stepper: {
    width: grow ? "100%" : "70%",
  },
  stepperText: {
    fontSize: theme.fontSizes.xs,
  },
  stepWrapper: {
    backgroundColor: theme.colors.white,
    flexWrap: "nowrap",
  },
  stepperLine: {
    marginTop: labelsUnder ? "25px" : undefined,
  },
  step: {
    flexDirection: "column",
    backgroundColor: "white",
  },
  stepIcon: {
    "&[data-completed=true]" : {
      backgroundColor: theme.colors.primary[5],
    },
    "&[data-progress=true]" : {
      borderColor: theme.colors.primary[5],
    },
    backgroundColor: "white",
    borderColor: "white",
  },
  stepIconCompleted: {
    backgroundColor: theme.colors.primary[5],
    borderRadius: "50%",
  },
  steps: {
    alignItems: labelsUnder ? "start" : undefined,
  },
  stepBody: {
    marginLeft: 0,
    marginTop: theme.spacing.xs,
    alignItems: "center",
  },
}));
