import React, {FC, useState} from "react";
import {
  GetOrderByUniqueAttributeQuery,
  GetRemovableItemChoicesQuery,
  GetUserProductPreferencesQuery,
} from "/@/generated/graphql";
import {CreateRemovableItemFullDentureStep} from "../../../../shared/types/orders/createOrder.types";
import {Grid, Space, Stepper} from "@mantine/core";
import {createStyles} from "@mantine/emotion";
import {CreateRemovableItemFullDentureTypology} from "/@/pages/products/create/removable-item/CreateRemovableItemFullDentureTypology";
import {CreateRemovableItemFullDentureInfo} from "/@/pages/products/create/removable-item/CreateRemovableItemFullDentureInfo";
import {CreateProductSummarySideBar} from "/@/pages/products/create/CreateProductSummarySideBar";
import {useCreateOrderStore} from "/@/shared/stores/orders/create-store.store";

type CreateRemovableItemFullDentureProps = {
  product: NonNullable<
    GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["products"]
  >[0];
  patient: GetOrderByUniqueAttributeQuery["getOrderByUniqueAttribute"]["patient"];
  userProductPreferences: GetUserProductPreferencesQuery;
  removableItemChoicesData: GetRemovableItemChoicesQuery;
};

export const CreateRemovableItemFullDenture: FC<
  CreateRemovableItemFullDentureProps
> = ({patient, product, removableItemChoicesData, order}) => {
  const [createProductStep] = useCreateOrderStore(state => [
    state.createProductStep,
  ]);
  const {classes} = useStyles();
  const [isWithPreferences, setIsWithPreferences] = useState<boolean>(false);
  const dataContent = () => {
    if (createProductStep === CreateRemovableItemFullDentureStep.Typology) {
      return (
        <CreateRemovableItemFullDentureTypology
          product={product}
          setIsWithPreferences={setIsWithPreferences}
        />
      );
    }
    if (createProductStep === CreateRemovableItemFullDentureStep.Item) {
      return (
        <CreateRemovableItemFullDentureInfo
          product={product}
          removableItemChoicesData={removableItemChoicesData}
          order={order}
        />
      );
    }
    return <div>ERROR create removable item full denture</div>;
  };

  return (
    <Grid
      columns={36}
      gutter={0}
      style={{height: "100%"}}
    >
      <Grid.Col
        span={
          createProductStep < CreateRemovableItemFullDentureStep.Review
            ? 26
            : 36
        }
        style={theme => ({padding: theme.spacing.lg})}
      >
        {createProductStep >= 2 && isWithPreferences === true ? (
          <Stepper
            active={createProductStep}
            classNames={{step: classes.stepper, stepBody: classes.stepBody}}
          >
            <Stepper.Step label="Patient" />
            <Stepper.Step label="Typologie" />
            <Stepper.Step label="Prothèse" />
          </Stepper>
        ) : (
          <Stepper
            active={
              !isWithPreferences && createProductStep > 2
                ? createProductStep - 1
                : createProductStep
            }
            classNames={{step: classes.stepper, stepBody: classes.stepBody}}
          >
            <Stepper.Step label="Patient" />
            <Stepper.Step label="Typologie" />
          </Stepper>
        )}
        <Space h="xl" />
        {dataContent()}
      </Grid.Col>
      {createProductStep !== CreateRemovableItemFullDentureStep.Review && (
        <Grid.Col
          span={10}
          style={theme => ({
            padding: theme.spacing.sm,
            backgroundColor: theme.colors.primaryLight[9],
          })}
        >
          <CreateProductSummarySideBar
            product={product}
            patient={patient}
          />
        </Grid.Col>
      )}
    </Grid>
  );
};

const useStyles = createStyles(theme => ({
  stepper: {
    flexDirection: "column",
  },
  stepBody: {
    marginLeft: 0,
    marginTop: theme.spacing.xs,
    alignItems: "center",
  },
}));
