import {Box, Space, Text, Title} from "@mantine/core";
import React, {Dispatch, FC} from "react";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {ButtonsSelect} from "/@/components/ButtonsSelect";
import {StepsButtons} from "/@/components/StepsButtons";
import {ItemType, UpdateRemovableItemDocument} from "/@/generated/graphql";
import {CreateRemovableItemFullDentureStep} from "../../../../shared/types/orders/createOrder.types";
import {
  FullDentureTypeChoices,
  NewFullDentureStepChoices,
  ProductFromGetOrderByUniqueAttributeQuery,
  yesNoChoices,
} from "/@/shared/types/products/products.types";
import {useMutation} from "@apollo/client";
import {useCreateOrderStore} from "/@/shared/stores/orders/create-store.store";

type FullDentureTypologyFormData = {
  productSpecificType: ItemType;
  isReplacement?: string;
  newFullDentureStep?: number;
};

type CreateRemovableItemFullDentureTypologyProps = {
  product: ProductFromGetOrderByUniqueAttributeQuery;
  setIsWithPreferences: Dispatch<React.SetStateAction<boolean>>;
};
export const CreateRemovableItemFullDentureTypology: FC<
  CreateRemovableItemFullDentureTypologyProps
> = ({product, setIsWithPreferences}) => {
  const [
    createProductIndex,
    updateCreateProductIndex,
    updateCreateProductStep,
  ] = useCreateOrderStore(state => [
    state.createProductIndex,
    state.updateCreateProductIndex,
    state.updateCreateProductStep,
    state.updateCreateOrderStep,
  ]);

  const newRemovableItem = product.removableItem[0];

  const isNewOrder = () => {
    // theses are default parameters, we assume that if they didn't change, it's a new order
    // the goal is preventing from having default parameter selected for a new order
    return (
      !newRemovableItem.itemMaterial
      && newRemovableItem.itemType === ItemType.FullDenture
      && !newRemovableItem.isReplacement
    );
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: {errors},
  } = useForm<FullDentureTypologyFormData>(
    !isNewOrder()
    ? {
      defaultValues: {
          productSpecificType: newRemovableItem.itemType === ItemType.ImmediateDenture ? ItemType.ImmediateDenture : ItemType.FullDenture,
        isReplacement: newRemovableItem.isReplacement ? "YES" : "NO",
        newFullDentureStep: newRemovableItem.itemType === ItemType.FullDentureWaxBite ? 1 : 2,
      },
    } : {},
  );
  const watchItemType = watch("productSpecificType");
  const watchIsReplacement = watch("isReplacement");

  const [updateRemovableItem] = useMutation(UpdateRemovableItemDocument, {
    refetchQueries: ["getOrderByUniqueAttribute"],
    awaitRefetchQueries: true,
  });

  if (!product.removableItem || product.removableItem.length <= 0) {
    return (
      <div>
        <Text>
          {"Erreur sur l'ajout de produit. Veuillez contacter le support."}
        </Text>
      </div>
    );
  }

  const onSubmit: SubmitHandler<FullDentureTypologyFormData> = data => {
    let finalItemType = ItemType.FullDenture;
    if (data.productSpecificType === ItemType.ImmediateDenture) {
      finalItemType = ItemType.ImmediateDenture;
    } else if (
      data.productSpecificType === ItemType.FullDenture &&
      data.newFullDentureStep === 1 &&
      data.isReplacement === "NO"
    ) {
      finalItemType = ItemType.FullDentureWaxBite;
    } else {
      finalItemType = ItemType.FullDenture;
    }
    updateRemovableItem({
      variables: {
        where: {
          id: newRemovableItem.id,
        },
        data: {
          itemType: {set: finalItemType},
          isReplacement: data.isReplacement
            ? {
                set: data.isReplacement === "YES" ? true : false,
              }
            : undefined,
          itemMaterial:
            finalItemType === ItemType.FullDenture
              ? {
                  connect: {
                    id: "ACRYLIC",
                  },
                }
              : undefined,
        },
      },
    })
      .then(() => {
        if (finalItemType === ItemType.FullDentureWaxBite) {
          setIsWithPreferences(false);
          updateCreateProductIndex(createProductIndex + 1);
        } else {
          setIsWithPreferences(true);
          updateCreateProductStep(CreateRemovableItemFullDentureStep.Item);
        }
      })
      .catch(() => {
        //console.log("error");
      });
  };

  return (
    <Box>
      <Title order={2}>{"Typologie de prothèse adjointe complète"}</Title>
      <Space h="md" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text
          size="sm"
          style={{fontWeight: "bold"}}
        >
          {"Type de PAC"}
        </Text>
        <Controller
          name="productSpecificType"
          rules={{required: true}}
          control={control}
          render={({field}) => (
            <ButtonsSelect
              data={FullDentureTypeChoices}
              value={field.value}
              onChange={value => field.onChange(value)}
            />
          )}
        />
        {errors?.productSpecificType?.type === "required" && (
          <Text
            size="sm"
            style={{color: "red"}}
          >
            Veuillez choisir un type de PAC
          </Text>
        )}
        {(watchItemType === ItemType.FullDenture ||
          watchItemType === ItemType.FullDentureWaxBite) && (
          <>
            <Space h="md" />
            <Text
              size="sm"
              style={{fontWeight: "bold"}}
            >
              {"Le patient possède-t-il déjà une PAC pré-existante?"}
            </Text>
            <Controller
              name="isReplacement"
              rules={{required: true}}
              control={control}
              render={({field}) => (
                <ButtonsSelect
                  data={yesNoChoices}
                  value={field.value}
                  onChange={value => field.onChange(value)}
                />
              )}
            />
            {errors?.isReplacement?.type === "required" && (
              <Text
                size="sm"
                style={{color: "red"}}
              >
                Veuillez choisir une option
              </Text>
            )}
          </>
        )}
        {(watchItemType === ItemType.FullDenture ||
          watchItemType === ItemType.FullDentureWaxBite) &&
          watchIsReplacement === "NO" && (
            <>
              <Space h="md" />
              <Text
                size="sm"
                style={{fontWeight: "bold"}}
              >
                {"Choisissez une étape"}
              </Text>
              <Controller
                name="newFullDentureStep"
                rules={{required: true}}
                control={control}
                render={({field}) => (
                  <ButtonsSelect
                    data={NewFullDentureStepChoices}
                    value={field.value}
                    onChange={value => field.onChange(value)}
                  />
                )}
              />
              {errors?.newFullDentureStep?.type === "required" && (
                <Text
                  size="sm"
                  style={{color: "red"}}
                >
                  Veuillez choisir une étape
                </Text>
              )}
            </>
          )}
        <Space h="xl" />
        <StepsButtons
          handleSubmit={() => handleSubmit(onSubmit)()}
          setPreviousAction={updateCreateProductIndex}
          previousStep={createProductIndex - 1}
          isFirst={createProductIndex < 1}
        />
      </form>
    </Box>
  );
};
