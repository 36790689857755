import React, {FC, useEffect, useState} from "react";
import {useAuthContext} from "/@/shared/contexts/AuthContext";
import {useQuery} from "@apollo/client";
import {
  GetAccountProductPreferencesDocument,
  GetAccountProductPreferencesQuery,
  ItemType,
} from "/@/generated/graphql";
import {Box, Space, Title} from "@mantine/core";
import {ButtonsSelect} from "/@/components/ButtonsSelect";
import {
  productPreferencesItemTypes,
  generalPreferences,
  generalPreferencesEnum,
} from "/@/shared/types/product-preferences/productPreferences.types";
import {UpdateFixedProsthesisPreferences} from "/@/pages/settings/UpdateFixedProsthesisPreferences";
import {UpdateGeneralProductPreferences} from "/@/pages/settings/UpdateGeneralProductPreferences";

export const DentistProductPreferences: FC = () => {
  const {user} = useAuthContext();
  const {
    data: getAccountProductPreferencesQuery,
    loading: getAccountProductPreferencesLoading,
  } = useQuery(GetAccountProductPreferencesDocument, {
    fetchPolicy: "network-only",
  });
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedItemType, setSelectedItemType] = useState<ItemType | null>(
    null,
  );
  const [selectedGeneralPreference, setSelectedGeneralPreference] =
    useState<generalPreferencesEnum | null>(null);
  const [selectedGeneralPreferenceValue, setSelectedGeneralPreferenceValue] = useState(null);
  const [selectedPreferences, setSelectedPreferences] =
    useState<
      NonNullable<
        GetAccountProductPreferencesQuery["getAccountProductPreferences"][0]["fixedProsthesisPreferences"]
      >[0]
    >();

  useEffect(() => {
    if (selectedItemType && selectedUser) {
      const selectFixedProsthesisPreferencesArray =
        getAccountProductPreferencesQuery?.getAccountProductPreferences.find(
          userPreferences => userPreferences.dentist.id === selectedUser,
        )?.fixedProsthesisPreferences;
      const selectFixedProsthesisPreference =
        selectFixedProsthesisPreferencesArray?.find(
          preferences => preferences.itemType === selectedItemType,
        );
      if (selectFixedProsthesisPreference) {
        setSelectedPreferences(selectFixedProsthesisPreference);
      } else if (!selectFixedProsthesisPreference) {
        setSelectedPreferences(undefined);
      }
    }
  }, [selectedUser, selectedItemType]);

  useEffect(() => {
    if (selectedGeneralPreference){
      setSelectedGeneralPreferenceValue(
        getAccountProductPreferencesQuery?.getAccountProductPreferences.find(
          userPreferences => userPreferences.dentist.id === selectedUser && userPreferences?.productPreferenceType === selectedGeneralPreference,
        ),
      );
    }
  }, [selectedUser, selectedGeneralPreference]);

  if (getAccountProductPreferencesLoading) {
    return <Box />;
  }
  if (
    !getAccountProductPreferencesQuery ||
    getAccountProductPreferencesQuery.getAccountProductPreferences.length === 0
  ) {
    return (
      <Box>
        {
          "Erreur de récupération des préférences. Veuillez contacter l'équipe Jasper"
        }
      </Box>
    );
  }
  console.log();
  return (
    <Box m="md">
      {user?.userGroup?.id && (
        <Box>
          <Title
            order={4}
            style={theme=>({color: theme.colors.primary[2]})}
          >
            {"Pour qui souhaitez-vous modifier les préférences ?"}
          </Title>
          <Space h="sm" />
          <ButtonsSelect
            value={selectedUser}
            numberOfColumns={3}
            data={(user?.userGroup?.groupMembers ?? [])
              .filter(dentist => !dentist.isManager)
              .map(usr => ({
                value: usr.id,
                label: `${usr.firstName} ${usr.lastName}`,
              }))}
            onChange={value => {
              setSelectedItemType(null);
              setSelectedUser(value);
            }}
          />
        </Box>
      )}
      <Space h="xl" />
      {selectedUser && (
        <Box>
          <Title
            order={4}
            style={theme=>({color: theme.colors.primary[2]})}
          >
            {"Préférences par prothèse"}
          </Title>
          <ButtonsSelect
            numberOfColumns={3}
            value={selectedItemType}
            data={productPreferencesItemTypes}
            onChange={setSelectedItemType}
          />
        </Box>
      )}
      {selectedItemType && selectedPreferences && (
        <Box>
          <Space h="md" />
          <UpdateFixedProsthesisPreferences
            selectedPreferences={selectedPreferences}
            itemType={selectedItemType}
          />
        </Box>
      )}
      {selectedItemType && !selectedPreferences && (
        <Box>
          {"Aucune préférence n'a été enregistrée pour ce type de produit"}
        </Box>
      )}
      <Space h="xl" />
      {selectedUser && (
        <Box>
          <Title
            order={4}
            style={theme=>({color: theme.colors.primary[2]})}
          >
            {"Général"}
          </Title>
          <ButtonsSelect
            numberOfColumns={3}
            value={selectedGeneralPreference}
            data={generalPreferences}
            onChange={setSelectedGeneralPreference}
          />
        </Box>
      )}
      {selectedGeneralPreference && (
        <Box>
          <Space h="md" />
          <UpdateGeneralProductPreferences
            preferenceType={selectedGeneralPreference}
            selectedPreferences={selectedGeneralPreferenceValue}
            selectedDentistId={selectedUser}
          />
        </Box>
      )}
    </Box>
  );
};
